import { Address } from '@Types/account/Address';
import { BusinessUnit } from '@Types/business-unit/BusinessUnit';

export const getAddress = (addressType: string, businessUnit: BusinessUnit) => {
    const defaultAddressId = ('shipping' === addressType) ? businessUnit.defaultShippingAddressId : businessUnit.defaultBillingAddressId;
    if (defaultAddressId === undefined) {
        return Object.assign([], ('shipping' === addressType) ? businessUnit.shippingAddresses?.shift() : businessUnit.billingAddresses?.shift());
    }

    return businessUnit.addresses?.find((address) => {
        address.id === defaultAddressId;
    });
}

export const getAddresses = (addressType: string, businessUnit: BusinessUnit) => {
    const addressesIds = ('shipping' === addressType) ? businessUnit.shippingAddressIds : businessUnit.billingAddressIds;
    const filteredAddressList = businessUnit.addresses.filter((address) => {
        const found = addressesIds?.filter((addressesId) => {
          return addressesId === address.id;
        });
    
        return found.length > 0;
      });
    
      return filteredAddressList;
}

export const getFirstAddress = (addressType: string, businessUnit: BusinessUnit): Address => {
    const addresses = Object.assign([], ('shipping' === addressType) ? businessUnit.shippingAddressIds : businessUnit.billingAddressIds);
    const addressId = addresses?.shift();
    if (addressId === undefined) {
        return null;
    }

    return businessUnit.addresses?.find((address) => 
        address.id === addressId
    );
}

export const getAddressByIdAndType = (id: string, addressType: string, businessUnit: BusinessUnit) => {
    const addressIds = 'shipping' === addressType ? businessUnit.shippingAddressIds : businessUnit.billingAddressIds;
    // Check address type is correct
    if (addressIds?.find((address) => 
        address === id
    )?.length === 0 || addressIds === undefined) {
        return null;
    }

    return businessUnit.addresses?.find((address) => 
        address.id === id
    );
}