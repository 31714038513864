import Slider, { SliderProps } from 'components/commercetools-ui/slider';
import FullWidthSliderItem from './slider-item';
import styles from './index.module.scss';

const FullWidthSlider = ({ data }) => {
  const sliderFixedMood: SliderProps = {
    slidesPerView: 1,
    arrows: true,
    dots: true,
    loop: true,
  };
  const sliderConfiguration: SliderProps = sliderFixedMood;

  return (
    <div className={`bic ${styles.fullWidthSlider}`}>
      <div className={`${styles.sliderContainer}`}>
        <Slider {...sliderConfiguration}>
          {data.image.map((banner, index: number) => (
            <FullWidthSliderItem
              image={banner.image}
              titleText={banner.titleText}
              descriptionText={banner.descriptionText}
              buttonLabel={banner.buttonLabel}
              buttonUrl={banner.buttonUrl}
              bannerUrl={banner.bannerUrl}
              key={index}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default FullWidthSlider;
