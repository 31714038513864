import React, { useState, useRef, useEffect } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FlyingCartButton } from 'components/commercetools-ui/header/flying-cart-button';
import MegaMenuContent from 'bic/components/commercetools-ui/header/mega-menu-content';
import Typography from 'components/commercetools-ui/typography';
import { useAccount } from 'frontastic/provider';
import styles from 'bic/components/commercetools-ui/header/index.module.scss';

type Props = {
  data: any;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const BicNavigationTastic: React.FC<Props> = ({ data }) => {
  const { account } = useAccount();
  const [isActivePanel, setActivePanel] = useState('0');
  const [isActiveCategory, setActiveCategory] = useState('0');
  const navigationRef = useRef<HTMLDivElement>(null);

  const toggleItem = (index) => {
    if (isActivePanel === index) {
      return setActivePanel('0');
    }
    setActivePanel(index);
    setActiveCategory('0');
  };

  useEffect(() => {
    const handleClickOutsideNavigation = (e) => {
      if (!navigationRef?.current?.contains(e.target)) {
        setActivePanel('0');
      }
    };

    document.addEventListener('mousedown', handleClickOutsideNavigation);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideNavigation);
    };
  });

  const handleNavItem = (categoryIdx) => {
    setActivePanel('0');
    setActiveCategory(categoryIdx);
  };

  // Check and add Class active to navigation
  useEffect(() => {
    const breadcrumbDom = document.querySelector('.breadcrumb li:nth-child(2)');
    const navItems = document.querySelectorAll('.nav-item');
    if (breadcrumbDom !== null) {
      const breadcrumbText = breadcrumbDom.querySelector('a').innerHTML;

      navItems.forEach((item) => {
        item.firstElementChild.classList.remove('active');
        const subnav = item.firstElementChild;
        if (subnav) {
          if (subnav.firstElementChild.innerHTML === breadcrumbText) {
            item.firstElementChild.classList.add('active');
          }
        }
      });
    } else {
      navItems.forEach((item) => {
        item.firstElementChild.classList.remove('active');
      });
    }
  });

  const navigation = data?.categories?.dataSource || { categories: [] };
  return (
    <div className={`${data.bgColor} ${styles.bicNavigation} relative`} id="bicNavigation">
      {/* Mega menus */}
      {!!account && (
        <div className={`${styles.bicNavigationWrapper}`} ref={navigationRef}>
          {navigation.categories?.map((category, categoryIdx) => (
            <React.Fragment key={category.slug}>
              {!category.subCategories?.length && (
                <div className={`nav-item`} onClick={() => handleNavItem(categoryIdx)}>
                  <NextLink href={category.slug}>
                    <a
                      className={`${data.textColor} ${styles.navItem} ${
                        isActiveCategory === categoryIdx ? 'active' : ''
                      }`}
                    >
                      <span>
                        <Typography>{category.name}</Typography>
                      </span>
                    </a>
                  </NextLink>
                </div>
              )}
              {!!category.subCategories?.length && (
                <div className="nav-item">
                  <button
                    className={classNames(
                      open ? styles.subNavOpen : '',
                      isActivePanel === categoryIdx ? 'subNavIsShowing' : '',
                      isActiveCategory === categoryIdx ? 'active' : '',
                      styles.navItem,
                      'nav-item-btn',
                    )}
                    onClick={() => toggleItem(categoryIdx)}
                  >
                    <span className={`${data.textColor}`}>{category.name}</span>
                  </button>
                  {/* {isComponentVisible && ( */}
                  <div className={`subNavWrapper top-0 w-full ${styles.subNav}`}>
                    <MegaMenuContent
                      category={category}
                      categoryIdx={categoryIdx}
                      handleCloseSubNav={setActivePanel}
                      setActiveCategory={setActiveCategory}
                    />
                  </div>
                  {/* )} */}
                </div>
              )}
            </React.Fragment>
          ))}
          {data.showQuickAdd && (
            <div className="inline h-full grow">
              <span className={`text-md flex justify-end px-4 py-2 font-semibold ${data.textColor}`}>
                <FlyingCartButton />
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BicNavigationTastic;
