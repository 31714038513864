import React, { SyntheticEvent, useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import { Variant } from '@Types/product/Variant';
import { LoadingIcon } from 'components/commercetools-ui/icons/loading';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';

type Props = {
  selectedSubscriptions?: Variant[];
  selectedConfigurableComponents?: Variant[];
  quantity?: number;
  variant: Variant;
  disabled?: boolean;
  onAddedToCart?: () => void;
};

const AddToCartButton: React.FC<Props> = ({
  selectedSubscriptions,
  selectedConfigurableComponents,
  quantity,
  variant,
  disabled,
  onAddedToCart,
}) => {
  const { addItem, data: cart } = useCart();
  const [inventoryNone] = useState(cart?.inventoryMode == 'None');

  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);

  const handleAddToCart = async (event: SyntheticEvent, variant: Variant) => {
    event.preventDefault();
    if (true === event.currentTarget.closest('form').reportValidity()) {
      setIsLoading(true);
      await addItem(variant, quantity, selectedSubscriptions, selectedConfigurableComponents);
      setIsLoading(false);
      setAdded(true);
      if (onAddedToCart) {
        onAddedToCart();
      }
    }
  };
  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false);
      }, 1000);
    }
  }, [added]);

  if (!cart) {
    return (
      <button type="button" className="btn-primary" disabled>
        {formatProductMessage({ id: 'no.cart', defaultMessage: 'No cart available' })}
      </button>
    );
  }

  return (
    <>
      {!cart.isPreBuyCart && (
        <button
          type="button"
          onClick={(event) => handleAddToCart(event, variant)}
          className="btn-primary btn-add-to-cart"
          disabled={(!inventoryNone && !variant.isOnStock) || isLoading || disabled}
        >
          {!isLoading && !added && (
            <>
              {inventoryNone || variant.isOnStock
                ? formatProductMessage({ id: 'cart.add', defaultMessage: 'Add to Cart' })
                : formatProductMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' })}
            </>
          )}

          {isLoading && <LoadingIcon className="mx-auto animate-spin" />}
          {!isLoading && added && <CheckIcon className="mx-auto" />}
        </button>
      )}
      {cart.isPreBuyCart && (
        <button
          type="button"
          onClick={(event) => handleAddToCart(event, variant)}
          className="btn-primary"
          disabled={isLoading || disabled}
        >
          {!isLoading && !added && formatProductMessage({ id: 'cart.add', defaultMessage: 'Add to Cart' })}

          {isLoading && <LoadingIcon className="mx-auto animate-spin" />}
          {!isLoading && added && <CheckIcon className="mx-auto" />}
        </button>
      )}
    </>
  );
};

export default AddToCartButton;
