import React from 'react';
import { RegisterProps } from 'components/commercetools-ui/account/register';
import CustomerRegister from 'bic/components/customer-register';
export interface Props {
  data: RegisterProps;
}

const CustomerRegisterTastic: React.FC<Props> = ({ data }) => {
  return <CustomerRegister {...data} />;
};

export default CustomerRegisterTastic;
