import React, { useEffect, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import WishlistButton from 'components/commercetools-ui/wishlist-button';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useAvailability } from 'helpers/hooks/useAvailability';
import { useFormat } from 'helpers/hooks/useFormat';
import { useBundlesHook } from 'helpers/hooks/useSubscription';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import { UIProduct } from '..';
import AddToCartButton from '../add-to-cart-button';
import ConfigurableComponents from '../configurable-components';
import Subscriptions from '../subscriptions';
import { LineItem } from '@Types/cart/LineItem';
import { useBusinessUnitStateContext } from 'frontastic/provider/BusinessUnitState';

type Props = {
  product: UIProduct;
  subscriptions?: Product[];
  configurableComponents?: Product[];
  onChangeVariantIdx: (idx: number) => void;
  hideAddTocartButton?: boolean;
  hideWishlistButton?: boolean;
  hideAvailability?: boolean;
  variant: Variant;
};

const DropdownVariantSelector: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  product,
  subscriptions,
  configurableComponents,
  variant,
  onChangeVariantIdx,
  hideAddTocartButton,
  hideWishlistButton,
  hideAvailability,
  className,
}) => {
  const { businessUnit } = useBusinessUnitStateContext();
  const { data: cart } = useCart();
  const [inventoryNone] = useState(cart?.inventoryMode == 'None');

  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { handleBundleSelect: handleSubscriptionSelect, selectedBundles: selectedSubscriptions } =
    useBundlesHook(subscriptions);
  const { setSelectedBundles: setConfigurableComponents, selectedBundles: selectedConfiguirableComponent } =
    useBundlesHook(configurableComponents);

  const [isAddToCartDisabled, setIsAddToCartDisabled] = useState(false);
  const [selected, setSelected] = useState(variant.sku);
  const [query, setQuery] = useState('');
  const { availableQuantity } = useAvailability(product?.variants?.[0]);
  const qtyMode = businessUnit.custom.fields.quantityMode ?? "sku";
  const [step] = useState(qtyMode === "sku" ? parseInt(variant?.attributes['bic_qty_increment'] || '1') : parseInt(variant?.attributes['number_of_sku_by_outer'] || '1'));
  const [min] = useState(qtyMode === "sku" ? parseInt(variant?.attributes['bic_qty_increment'] || step) : parseInt(variant?.attributes['number_of_sku_by_outer'] || step));
  const [max] = useState(qtyMode === "sku" ? parseInt(variant?.attributes['bic_max_sale_qty']) || 999999999 : 999999999);
  const [qty, setQty] = useState(min.toString());

  const filteredSKUs =
    query === ''
      ? product?.variants.map((item) => item.sku)
      : product?.variants
          .map((item) => item.sku)
          .filter((item) => item.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')));

  const restockDate = new Date();
  restockDate.setDate(restockDate.getDate() + (variant?.availability?.restockableInDays || 0));

  useEffect(() => {
    if (selected) {
      const index = product?.variants?.findIndex((variant) => variant.sku === selected);
      if (index > -1) {
        onChangeVariantIdx(index);
      }
    }
  }, [selected]);

  const handleQtyInput = (e) => {
    setQty(e.target.value);
  };

  const handleOnBlurQty = () => {
    if (qty === '' || qty === 'e') {
      setQty(min.toString());
    }
  };

  const handlePrice = () => {
    let intQty = parseInt(qty);
    if (Number.isNaN(intQty)) {
      intQty = 1;
    }
    return (intQty * product?.price?.centAmount ?? 0) / 100;
  };

  const handleUnitPrice = () => {
    return product?.price?.centAmount ?? 0;
  };

  return (
    <div className={`w-full ${className}`}>
      <p className="product-price mb-4">
        {CurrencyHelpers.formatForCurrency(
          CurrencyHelpers.formatToMoneyWithCurrency(product?.price?.preciseAmount/10000, product?.price?.currencyCode),
        )}
      </p>
      <div className="flex items-center">
        <div className="product-qty-wrapper">
          <input
            type="number"
            id="qty"
            name="qty"
            className="product-qty-input pl-0"
            onChange={handleQtyInput}
            onBlur={handleOnBlurQty}
            value={qty}
            step={step}
            min={min}
            max={max}
            disabled={!inventoryNone && !product?.variants?.[0].isOnStock && !cart?.isPreBuyCart}
          />
        </div>
        <div className="flex flex-wrap">
          <p className="product-price ml-8">
            <span className='ml-5'>Total price: </span>
            {CurrencyHelpers.formatForCurrency(
              CurrencyHelpers.formatToMoneyWithCurrency(handlePrice(), product?.price?.currencyCode, 2),
            )}
          </p>
        </div>
      </div>
      <div className="mt-6">
        {(0 < product?.variants[0].attributes.number_of_sku_by_outer ?? 0) && <p className='my-2 font-extrabold'><span className='font-color-bic'>Outer</span> quantity: <span className='font-normal'>{(parseInt(qty)/product?.variants[0].attributes.number_of_sku_by_outer).toFixed(3)}</span></p> }
        {(0 < product?.variants[0].attributes.number_of_sku_by_layer ?? 0) && <p className='my-2 font-extrabold' ><span className='font-color-bic'>Layer</span> quantity: <span className='font-normal'>{(parseInt(qty)/product?.variants[0].attributes.number_of_sku_by_layer).toFixed(3)}</span></p> }
        {(0 < product?.variants[0].attributes.number_of_sku_by_pallet ?? 0) && <p className='my-2 font-extrabold'><span className='font-color-bic'>Pallet</span> quantity: <span className='font-normal'>{(parseInt(qty)/product?.variants[0].attributes.number_of_sku_by_pallet).toFixed(3)}</span></p> }
        {/*product?.variants[0].attributes.bic_plant_number && <p style={{border: '1px solid', borderColor: '#F6F6F6', borderRadius: '8px', padding: '12px'}}className='my-2 font-color-bic'><svg style={{float: 'inline-start'}} width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className='mr-3'>  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="17" r="2" />  <circle cx="17" cy="17" r="2" />  <path d="M5 17h-2v-4m-1 -8h11v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" />  <line x1="3" y1="9" x2="7" y2="9" /></svg><div className='text-black'>{formatProductMessage({ id: 'productAvailableForDirectFactoryShipping', defaultMessage: 'Product available for direct factory shipping' })}</div></p>*/}
      </div>
      {product?.variants?.length > 1 && (
        <div className="mt-6">
          <p className="text-sm font-semibold">SKUs:</p>

          <Combobox value={selected} onChange={setSelected}>
            <div className="relative mt-1">
              <div className="">
                <Combobox.Input
                  className="input input-primary"
                  displayValue={(sku: string) => sku}
                  onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>
              </div>
              <Transition
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className="absolute z-40 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredSKUs.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
                  ) : (
                    filteredSKUs.map((sku) => (
                      <Combobox.Option
                        key={sku}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? 'bg-teal-600 text-white' : 'text-gray-900'
                          }`
                        }
                        value={sku}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{sku}</span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? 'text-white' : 'text-teal-600'
                                }`}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      )}
      {/* {!hideAvailability && !cart?.isPreBuyCart && variant.isOnStock && (
        <p className="text-sm text-gray-400">{`Available Qty: ${variant?.availability?.availableQuantity || 0}`}</p>
      )} */}
      {/* {!hideAvailability && !cart?.isPreBuyCart && !variant.isOnStock && (
        <>
          <p className="text-sm text-gray-400">
            {formatProductMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' })}
          </p>
          {!!variant?.availability?.restockableInDays && (
            <p className="text-sm text-gray-400">{`Expected restock date: ${restockDate.toLocaleDateString()}`}</p>
          )}
        </>
      )} */}
      {!!subscriptions?.length && (
        <Subscriptions
          subscriptions={subscriptions}
          onSubscriptionSelect={handleSubscriptionSelect}
          selectedSubscriptions={selectedSubscriptions}
        />
      )}
      {!!configurableComponents?.length && (
        <ConfigurableComponents
          className="py-4"
          product={product}
          configurableComponents={configurableComponents}
          updateAddToCartButtonState={setIsAddToCartDisabled}
          onConfigurationUpdate={setConfigurableComponents}
        />
      )}
      <div className="mt-6 items-center sm:flex">
        {!hideAddTocartButton && (
          <AddToCartButton
            disabled={isAddToCartDisabled}
            selectedSubscriptions={selectedSubscriptions}
            selectedConfigurableComponents={selectedConfiguirableComponent}
            variant={variant}
            quantity={parseInt(qty)}
          />
        )}
        {/* <div className="mt-3 sm:mt-0 sm:ml-5">
          {!hideWishlistButton && <WishlistButton variant={variant} isCompact />}
        </div> */}
      </div>
    </div>
  );
};

export default DropdownVariantSelector;
