import React, { useState, useEffect } from 'react';
import { Address } from '@Types/account/Address';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { ProjectSettings } from '@Types/ProjectSettings';
import { CountryOption } from 'helpers/countryOptions';
import { useFormat } from 'helpers/hooks/useFormat';
import { getTaxedCountries } from 'helpers/utils/getTaxedCountries';
import { useCart } from 'frontastic/provider';
import { FormData } from '..';
import { mapToCartStructure } from '../mapFormData';
import AddressForm from './addressForm';
import AddressSelection from './addressSelection';
import { useAccount } from 'helpers/hooks/useAccount';

type AddressProps = {
  data: FormData;
  updateData: (data: FormData) => void;
  billingIsSameAsShipping: boolean;
  toggleBillingAddressOption: () => void;
};

const Address: React.FC<AddressProps> = ({ data, updateData, billingIsSameAsShipping, toggleBillingAddressOption }) => {
  const [projectSettingsCountries, setProjectSettingsCountries] = useState<ProjectSettings>(null);
  const [shippingMethodsData, setShippingMethodsData] = useState<ShippingMethod[]>(null);
  const [availableCountryOptions, setAvailableCountryOptions] = useState<CountryOption[]>(null);
  const [selectedAddress, setSelectedAddress] = useState<Address>(null);
  const [addressShipping, setAddressShipping] = useState<Address>(null);
  const [addressBilling, setAddressBilling] = useState<Address>(null);
  const { getProjectSettings, getShippingMethods, updateCart } = useCart();
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { account } = useAccount();

  useEffect(() => {
    (async () => {
      const shippingMethods = await getShippingMethods();
      getProjectSettings().then((data) => {
        setProjectSettingsCountries(data);
        setShippingMethodsData(shippingMethods);
      });
    })();
  }, []);

  useEffect(() => {
    if (!shippingMethodsData || !projectSettingsCountries) {
      const showMessageInDropdown = {
        data: '',
        display: `${formatMessage({
          id: 'no.countries.available.for.shipping',
          defaultMessage: 'Currently there are no countries available for shipping',
        })}`,
      };
      setAvailableCountryOptions([showMessageInDropdown]);
    } else {
      const totalCountries = getTaxedCountries(shippingMethodsData, projectSettingsCountries?.countries);

      setAvailableCountryOptions(totalCountries);
    }
  }, [projectSettingsCountries, shippingMethodsData]);

  const updateSelectionShipping = (address: Address, forceCartUpdate: boolean) => {
    setAddressShipping(address);
    const dataUpdated = updateDataWithShipping(address);
    const updatedData = mapToCartStructure(dataUpdated, billingIsSameAsShipping);
    if (forceCartUpdate) {
      updateCart(updatedData);
    }
  };
  const updateSelectionBilling = (address: Address, forceCartUpdate: boolean) => {
    setAddressBilling(address);
    const dataUpdated = updateDataWithBilling(address);
    const updatedData = mapToCartStructure(dataUpdated, billingIsSameAsShipping);
    if (forceCartUpdate) {
      updateCart(updatedData);
    }
  };
  const updateDataWithShipping = (address: Address): FormData => {
    data.shippingStreetName = address.streetName;
    data.shippingCity = address.city;
    data.shippingPostalCode = address.postalCode;
    data.shippingCountry = address.country;
    data.shippingRegion = address.region;
    data.shippingFax = address.fax;
    data.shippingMobile = address.mobile;
    data.shippingPOBox = address.pOBox;
    data.shippingApartment = address.apartment;
    data.shippingBuilding = address.building;
    data.shippingDepartment = address.department;
    data.shippingCompany = address.company;
    data.shippingTitle = address.title;
    data.shippingCustom = address.custom;
    data.shippingCompany = address.company
    data.email = account?.email
    return data;
  };

  const updateDataWithBilling = (address: Address): FormData => {
    data.billingStreetName = address.streetName;
    data.billingCity = address.city;
    data.billingPostalCode = address.postalCode;
    data.billingCountry = address.country;
    data.billingRegion = address.region;
    data.billingFax = address.fax;
    data.billingMobile = address.mobile;
    data.billingPOBox = address.pOBox;
    data.billingApartment = address.apartment;
    data.billingBuilding = address.building;
    data.billingDepartment = address.department;
    data.billingCompany = address.company;
    data.billingTitle = address.title;
    data.billingCustom = address.custom;
    data.billingCompany = address.company;
    data.email = account?.email;
    return data;
  };

  return (
    <section
      aria-labelledby="cart-heading"
      className="bg-white px-4 py-5 md:rounded md:px-6 md:shadow-md lg:col-span-7"
    >
      <div className="mb-4 text-xs font-bold uppercase leading-tight text-neutral-600">
        <span>{formatMessage({ id: 'shippingTo', defaultMessage: 'Shipping to' })}</span>
      </div>

      <div>
        <AddressSelection
          updateSelection={updateSelectionShipping}
          className="mb-4"
          addressType="shipping"
          data={data}
        />
      </div>
      <div className="mb-4 text-xs font-bold uppercase leading-tight text-neutral-600">
        <span>{formatMessage({ id: 'billingTo', defaultMessage: 'Billing to' })}</span>
      </div>
      <div>
        {!!selectedAddress && true && (
          <label className="mt-4 flex items-center rounded bg-neutral-200 p-4 text-sm">
            <input
              id="billing-same-as-shipping"
              type="checkbox"
              className="mr-2 text-xl"
              checked={billingIsSameAsShipping}
              onChange={toggleBillingAddressOption}
            />
            {formatMessage({
              id: 'billingDetailsLabel',
              defaultMessage: 'Billing address is the same as shipping address',
            })}
          </label>
        )}
        {!billingIsSameAsShipping && (
          <AddressSelection
            updateSelection={updateSelectionBilling}
            className="mb-4"
            addressType="billing"
            data={data}
          />
        )}
      </div>

      {!selectedAddress && false && (
        <AddressForm
          toggleBillingAddressOption={toggleBillingAddressOption}
          updateData={updateData}
          data={data}
          billingIsSameAsShipping={billingIsSameAsShipping}
          availableCountryOptions={availableCountryOptions}
        />
      )}
    </section>
  );
};

export default Address;
