import { useProducts } from 'frontastic';

export const getLabel = (attributeKey: string, locale: string) => {
    const { attributes } = useProducts();
    const composedAttributeKey = attributeKey.split('.').reverse();
    let attribute = undefined;
    composedAttributeKey.every((attributeKeyPath) => {
        attribute = attributes.find((element) => {
            return element.key === attributeKeyPath;
        });
        return attribute === undefined
    })

    return attribute?.label[locale];
}

export const getType = (attributeKey: string) => {
    const { attributes } = useProducts();
    const composedAttributeKey = attributeKey.split('.').reverse();
    let attribute = undefined;
    composedAttributeKey.every((attributeKeyPath) => {
        attribute = attributes.find((element) => {
            return element.key === attributeKeyPath;
        });
        return attribute === undefined
    })

    return attribute?.type;
}

export const formatFacetValue = (attributeKey: string, value: string) => {
    const type = getType(attributeKey);
    switch(type.name) {
        case 'number':
            return value.endsWith(".0") ? value.split(".").slice(0, 1) : value;
        default:
            return value;
    }
}