import { Address } from '@Types/account/Address';

export const mapAddressToString = (address: Address): string => {
  const streetPieces = [
    address.streetName ?? '',
    address.streetNumber ?? ''
  ];

  const addressPieces = [
    `${address.company ? `${address.company},` : ''}
    ${streetPieces.join(', ')}
    ${address.postalCode ? `${address.postalCode},` : ''}
    ${address.country ? address.country : ''}`,
  ];
  return addressPieces.filter((piece) => piece).join(', ');
};
