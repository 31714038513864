import React from 'react';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import BicOrderDownloadDocument from './download-document-button';

export function BicOrderLineItem({ lineItem, bicOrder }) {
  return (
    <tr>
      <td className="py-2 pr-8">
        <div className="flex items-center">{lineItem.name}</div>
      </td>
      <td className="py-2 pr-8">
        <div className="flex items-center">{lineItem.sku}</div>
      </td>
      <td className="py-2 pr-8">
        <div className="flex items-center">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: bicOrder.currency, minimumSignificantDigits: 3 }).format(lineItem.price)}</div>
      </td>
      <td className="py-2 pr-8">
        <div className="flex items-center">{lineItem.qty}</div>
      </td>
      <td className="py-2 pr-8">
        {CurrencyHelpers.formatForCurrency({centAmount: lineItem.subtotal * 100, currencyCode: bicOrder.currency, minimumFractionDigits: 2 })}
      </td>
      <td className="py-2 pr-8">
        <div className="flex items-center">{lineItem.status}</div>
      </td>
      <td className="py-2 pr-8">
        <div className="flex items-center">
          <BicOrderDownloadDocument
            title="Invoice"
            order={lineItem.invoice_number.concat('|').concat(lineItem.erp_line_id)}
            docType="IN"
          />
        </div>
      </td>
      <td className="py-2 pr-8">
        <div className="flex items-center">
          <BicOrderDownloadDocument
            title="Delivery note"
            order={bicOrder.erp_order_number.concat('|').concat(lineItem.erp_line_id)}
            docType="DN"
          />
        </div>
      </td>
      <td className="py-2 pr-8">
        <div className="flex items-center">
          <BicOrderDownloadDocument
            title="Packing list"
            order={bicOrder.erp_order_number.concat('|').concat(lineItem.erp_line_id)}
            docType="PL"
          />
        </div>
      </td>
    </tr>
  );
}
