import React, { useCallback, useEffect, useState } from 'react';
import { ShoppingCartIcon } from '@heroicons/react/outline';
import { Organization } from '@Types/organization/organization';
import debounce from 'lodash.debounce';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import { calculateCartCount } from 'helpers/utils/calculateCartCount';
import { useCart } from 'frontastic';
import CartsExplorerButton from 'components/commercetools-ui/cart/cartsExplorerButton';
import Image from 'frontastic/lib/image';
import styles from './index.module.scss';

interface CartButtonProps {
  cartLink?: Reference;
  organization?: Organization;
}

const CartButton: React.FC<CartButtonProps> = ({ cartLink, organization }) => {
  const { data: cart } = useCart();
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [cartItemCount, setCartItemCount] = useState(0);
  const [isCartDisabled, setIsCartDisabled] = useState(true);

  const debouncedSetCartDisabled = useCallback(
    debounce((value) => {
      setCartItemCount(calculateCartCount(value?.lineItems || []));
      setIsCartDisabled(typeof value === 'undefined');
    }, 2000),
    [],
  );

  useEffect(() => {
    debouncedSetCartDisabled(cart);
  }, [cart]);

  return (
    <>
      <ReferenceLink target={cartLink} className={`cart-button relative ${isCartDisabled ? '--disabled' : ''}`}>
        <Image src="/images/icon/cart.svg" alt="" className={styles.cartIcon} />
        {cartItemCount > 0 && (
          <>
            <span className={`${styles.cartItemQty} absolute rounded-full bg-accent-400 px-1`}>
              <span className="flex h-full w-full items-center justify-center text-xs text-white group-hover:text-white">
                {cartItemCount}
              </span>
            </span>
            <span className="ml-2 hidden">
              {formatCartMessage({
                id: 'myCart',
                defaultMessage: 'My Cart',
              })}
            </span>
          </>
        )}
      </ReferenceLink>
      {!!organization.superUserBusinessUnitKey && <CartsExplorerButton className="ml-2 h-4 w-4" />}
    </>
  );
};

export default CartButton;
