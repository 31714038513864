import React, { useEffect, useRef } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import styles from './index.module.scss';

export interface Props extends React.ComponentProps<'input'> {
  onSubmit?: () => void;
}

const SearchInput: React.FC<Props> = ({ onSubmit, ...props }) => {
  //formatted messages
  const { formatMessage } = useFormat({ name: 'common' });

  //input ref
  const ref = useRef<HTMLInputElement>(null);

  //form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit?.();
  };

  useEffect(() => {
    //upon mounting we initially focus the input
    ref.current.focus();
  }, []);

  return (
    <>
      <button type="button" className={`hidden ${styles.searchButtonMobile}`}>
        <Image src="/images/icon/search.svg" alt="" />
      </button>
      <form className={`z-1 flex w-full bg-white ${styles.searchForm}`} onSubmit={handleSubmit}>
        <label htmlFor="email" className="sr-only">
          Search
        </label>
        <button type="submit" className={`${styles.searchSubmitButton}`}>
          <Image src="/images/icon/search.svg" alt="" />
        </button>
        <input
          type="text"
          name="search"
          id="search"
          className={`${styles.searchInput} block w-full`}
          placeholder={`${formatMessage({ id: 'search', defaultMessage: 'Search' })}`}
          {...props}
          ref={ref}
        />
      </form>
    </>
  );
};

export default SearchInput;
