import React, { ChangeEvent, useRef, useState } from 'react';
import { ShoppingCartIcon, TrashIcon } from '@heroicons/react/outline';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import debounce from 'lodash.debounce';
import { useCart, useProducts } from 'frontastic';
import Image from 'frontastic/lib/image';
import { LoadingIcon } from '../icons/loading';

const getInitialLineItem = () => ({
  id: new Date().getTime().toString(),
  value: '',
  items: [],
  selectedVariant: null,
  selectedProduct: null,
  selectedQuantity: '1',
});

interface Props {
  goToProductPage: (_url: string) => void;
}

const AddToCartItem: React.FC<Props> = ({ goToProductPage }) => {
  const { query } = useProducts();
  const { addItem } = useCart();
  const { formatMessage } = useFormat({ name: 'cart' });

  const [isLoading, setIsLoading] = useState(false);
  const [lineItem, setLineItem] = useState(getInitialLineItem());

  const lineItemInputRef = useRef<HTMLInputElement>(null);
  const lineItemQuantityRef = useRef<HTMLInputElement>(null);

  const {
    data: { isPreBuyCart },
  } = useCart();

  const debouncedQuery = debounce(async (text) => {
    setIsLoading(true);

    const { items }: { items: Product[] } = await query(`query=${text}`);
    setLineItem({
      ...lineItem,
      items,
    });
    setIsLoading(false);
  }, 500);

  const updateItem = async (key: string, value: any) => {
    setLineItem({
      ...lineItem,
      [key]: value,
    });
  };

  const updateItemValue = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length >= 2) {
      debouncedQuery(event.target.value);
    } else {
      updateItem('items', []);
    }
  };

  const selectProductAsLineItem = async (product: Product) => {
    if (product.variants.length === 1) {
      await setLineItem({
        ...lineItem,
        selectedVariant: product.variants[0],
        selectedProduct: product,
        items: [],
      });
      lineItemQuantityRef.current?.focus();
    } else {
      updateItem('selectedProduct', product);
      lineItemInputRef.current?.focus();
    }
  };

  const selectVariantAsLineItem = async (variant: Variant) => {
    updateItem('selectedVariant', variant);
    await setLineItem({
      ...lineItem,
      selectedVariant: variant,
      items: [],
    });
    lineItemQuantityRef.current?.focus();
  };

  const getVariantName = (attributes: Record<string, any>) => {
    return Object.keys(attributes)
      .map((key) => {
        if (typeof attributes[key] === 'object') {
          return typeof attributes[key].label === 'undefined' ? null : `${key}: ${attributes[key].label}`;
        }
        return typeof attributes[key] === 'undefined' ? null : `${key}: ${attributes[key]}`;
      })
      .filter((item) => item)
      .join(', ');
  };

  const handleChange = (e) => {
    setLineItem({ ...lineItem, selectedQuantity: e.target.value });
  };

  const addItemToCart = async () => {
    setIsLoading(true);
    await addItem(lineItem.selectedVariant, parseInt(lineItem.selectedQuantity));
    setIsLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleChange(e);
      addItemToCart();
    }
  };

  const increment = () => {
    let incrementQty;
    if (incrementQty === '') {
      incrementQty = 0;
    } else {
      incrementQty = parseInt(lineItem.selectedQuantity) + 1;
    }
    setLineItem({ ...lineItem, selectedQuantity: incrementQty });
  };

  const decrement = () => {
    let decrementQty;
    if (decrementQty === '') {
      decrementQty = 0;
    } else {
      decrementQty = parseInt(lineItem.selectedQuantity) - 1;
    }
    setLineItem({ ...lineItem, selectedQuantity: decrementQty });
  };

  return (
    <tr className="line-item dynamic-cart-item">
      {!lineItem.selectedVariant && (
        <td colSpan={6} className="relative">
          <button type="submit" className="btn-search">
            <Image src="/images/icon/search.svg" alt="" />
          </button>
          <input
            id={`item_${lineItem.id}`}
            ref={lineItemInputRef}
            placeholder={formatMessage({ id: 'search-in-cart', defaultMessage: 'Search by SKU or product name...' })}
            type="text"
            className="dynamic-cart-item__input input input-primary w-full"
            onChange={(event) => updateItemValue(event)}
          />
          {isLoading && (
            <LoadingIcon className="dynamic-cart-item__input-loader mt-1/2 ml-2 h-4 w-4 animate-spin text-gray-400" />
          )}
          {!!lineItem.items?.length && !lineItem.selectedProduct && (
            <ol className="dynamic-cart-item__search absolute hidden">
              {lineItem.items.map((product) => (
                <li className="dynamic-cart-item__search-item" key={product.productId}>
                  <button className="w-full py-2 text-left" onClick={() => selectProductAsLineItem(product)}>
                    {product.slug}
                  </button>
                </li>
              ))}
            </ol>
          )}
          {!!lineItem.items?.length && !!lineItem.selectedProduct && (
            <ol className="dynamic-cart-item__search absolute hidden">
              {lineItem.selectedProduct.variants.map((variant) => (
                <li className="dynamic-cart-item__search-item" key={variant.id}>
                  <button onClick={() => selectVariantAsLineItem(variant)}>{getVariantName(variant.attributes)}</button>
                </li>
              ))}
            </ol>
          )}
          {!lineItem.items?.length && !isLoading && lineItemInputRef.current?.value.length >= 2 && (
            <ol className={`dynamic-cart-item__search absolute hidden`}>
              <li className="dynamic-cart-item__search-item">Nothing found</li>
            </ol>
          )}
        </td>
      )}
      {!!lineItem.selectedVariant && (
        <>
          <td className="border-[0] border-t border-solid border-neutral-900">
            <table className="inner-table">
              <tbody>
                <td className="td-line-item__image">
                  <Image
                    src={lineItem.selectedVariant.images[0]}
                    alt={lineItem.selectedVariant.name}
                    className="object-contain object-center"
                  />
                </td>
                <td className="td-line-item__details">
                  <p className="td__name">{lineItem.selectedProduct.name}</p>
                  <p className="td-other-details td-details__sku">
                    <label>Sku:</label> {lineItem.selectedVariant.sku}
                  </p>
                </td>
              </tbody>
            </table>
          </td>
          <td className="border-[0] border-t border-solid border-neutral-900 p-1 text-center">
            <div className="product-qty-wrapper mx-auto">
              <div className="product-qty-btns">
                <button
                  type="button"
                  className="product-qty-btn product-qty-btn-increase"
                  onClick={increment}
                  disabled={
                    (parseInt(lineItem.selectedQuantity) >= lineItem.selectedVariant.availability?.availableQuantity &&
                      !isPreBuyCart) ||
                    !lineItem.selectedVariant.availability?.availableQuantity
                  }
                >
                  <Image src="/images/icon/chevron.svg" alt="" />
                </button>
                <button
                  className="product-qty-btn product-qty-btn-decrease"
                  type="button"
                  onClick={decrement}
                  disabled={
                    parseInt(lineItem.selectedQuantity) <= 1 || (!lineItem.selectedVariant?.isOnStock && !isPreBuyCart)
                  }
                >
                  <Image src="/images/icon/chevron.svg" alt="" />
                </button>
              </div>
              <input
                type="number"
                value={lineItem.selectedQuantity}
                className="product-qty-input"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                disabled={isLoading}
              />
            </div>
            <p className="td-other-details td-details__availability mt-2">
              {lineItem.selectedVariant.availability?.availableQuantity > 0 && (
                <>
                  <label>In Stock:</label> {lineItem.selectedVariant.availability.availableQuantity}
                </>
              )}
              {lineItem.selectedVariant.availability?.availableQuantity <= 0 && <label>Out of stock</label>}
            </p>
          </td>
          <td
            className={`p-1_text price border-[0] border-t border-solid border-neutral-900 text-right ${
              lineItem?.selectedVariant?.discountedPrice ? 'discounted' : ''
            }`}
          >
            {lineItem.selectedVariant?.discountedPrice && (
              <div className="price">
                {CurrencyHelpers.formatForCurrency(lineItem.selectedVariant?.discountedPrice)}
              </div>
            )}
            <div className={`${lineItem?.selectedVariant?.discountedPrice ? 'old-price' : ''}`}>
              {CurrencyHelpers.formatForCurrency(lineItem.selectedVariant.price)}
            </div>
          </td>
          <td className="btns whitespace-nowrap border-[0] border-t border-solid border-neutral-900">
            <div className="">
              <button
                className="button button-primary--small"
                type="button"
                onClick={addItemToCart}
                disabled={parseInt(lineItem.selectedQuantity) === 0 || lineItem.selectedQuantity === ''}
              >
                {!isLoading && <ShoppingCartIcon className="h-4 w-4 text-white"></ShoppingCartIcon>}
                {isLoading && <LoadingIcon className="h-4 w-4 animate-spin text-gray-400" />}
              </button>
              <button
                className="button button-secondary--small ml-2"
                type="button"
                onClick={() => setLineItem(getInitialLineItem())}
              >
                <TrashIcon className="h-4 w-4"></TrashIcon>
              </button>
            </div>
          </td>
        </>
      )}
    </tr>
  );
};

export default AddToCartItem;
