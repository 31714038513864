import React from 'react';
import NextLink from 'next/link';
import Image from 'frontastic/lib/image';

export type BreadcrumbProps = {
  Separator?: React.ReactNode;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ children }) => {
  return (
    <nav className="breadcrumb flex" aria-label="Breadcrumb">
      <ol role="list" className="flex flex-wrap items-center">
        <li>
          <div>
            <NextLink href="/">
              <a className="test">
                <span>Home</span>
              </a>
            </NextLink>
          </div>
        </li>
        {React.Children.map(children, (Child) => {
          return (
            <li className="flex items-center">
              <span className="separator">
                <Image src="/images/icon/chevron.svg" alt="" />
              </span>
              <b className="font-bold">{Child}</b>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
