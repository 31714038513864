import React, { Fragment, useState } from 'react';
import { Account } from '@Types/account/Account';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon } from '@heroicons/react/outline';
import { BusinessUnit } from '@Types/business-unit/BusinessUnit';
import { Organization } from '@Types/organization/organization';
import Typography from 'components/commercetools-ui/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image, { NextFrontasticImage } from 'frontastic/lib/image';
import BusinessUnitDropdownTree from 'components/commercetools-ui/business-unit/dropdown-tree';
import BusinessUnitRole from 'components/commercetools-ui/business-unit/role';
import StorePicker from 'components/commercetools-ui/business-unit/store-picker';
import AccountButton from './account-button';
import CartButton from './cart-button';
import { FlyingCartButton } from 'components/commercetools-ui/header/flying-cart-button';
import HeaderMenu from 'components/commercetools-ui/header/header-menu';
import SearchButton from './search-button';
import WishListButton from 'components/commercetools-ui/header/wishlist-button';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import styles from './index.module.scss';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export interface Link {
  name: string;
  reference: Reference;
}

export interface HeaderProps {
  organization: Organization;
  organizationTree: BusinessUnit[];
  tagline?: string;
  links: Link[];
  wishlistItemCount?: number;
  logo: { media: NextFrontasticImage } | NextFrontasticImage;
  logoLink: Reference;
  account: Account;
  accountLink: Reference;
  businessUnitLink: Reference;
  wishlistLink?: Reference;
  cartLink: Reference;
}

const Header: React.FC<HeaderProps> = ({
  organization,
  organizationTree,
  tagline,
  links,
  wishlistItemCount,
  logo,
  logoLink,
  account,
  accountLink,
  businessUnitLink,
  wishlistLink,
  cartLink,
}) => {
  const [isDesktopSize] = useMediaQuery(desktop);
  const [open, setOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const handleMenuActive = () => {
    document.getElementById('bicNavigation').classList.toggle('bicNavigationOpen');
    document.documentElement.classList.toggle('menu-open');
    document.querySelector('.hamburgerBtn').classList.toggle('menuIsActive');
  };

  return (
    <div className="fixed-screen-width lg:relative-width header-wrapper px-4">
      {/* Mobile menu */}
      <HeaderMenu open={open} setOpen={setOpen} links={links} navigation={{ categories: [] }} />

      <header className="relative">
        {tagline && (
          <p className="">
            <Typography>{tagline}</Typography>
          </p>
        )}

        <div className="top">
          {!!account && (
            <div>
              <div className={`hidden justify-end pt-2 md:flex ${styles.customerAccount}`}>
                <AccountButton
                  account={account}
                  accountLink={accountLink}
                  organization={organization}
                  businessUnitLink={businessUnitLink}
                />
                <span className="ml-2 font-bold">
                  {formatAccountMessage({ id: 'welcome', defaultMessage: 'Welcome' }) +
                    ', ' +
                    (account?.firstName ?? account?.lastName ?? 'User')}
                </span>
              </div>
              <div className={`${styles.headerContent} flex items-center justify-between pt-6`}>
                {/* Logo */}
                <ReferenceLink target={logoLink}>
                  <span className="sr-only">Catwalk</span>
                  <div className={styles.logo}>
                    <Image media={logo.media ? logo.media : { media: {} }} />
                  </div>
                </ReferenceLink>

                <div>{!!account && <>{showSearch || isDesktopSize ? <SearchButton /> : null}</>}</div>

                <div className={`${styles.storeBu} gap-3 md:flex`}>
                  <div className={`${styles.selection}`}>
                    <BusinessUnitDropdownTree tree={organizationTree} />
                  </div>
                  <div className={`${styles.selection}`}>
                    <StorePicker organization={organization} />
                  </div>
                </div>
                <span className="hidden px-4">Country: GB</span>
                <span className="hidden px-4">Language: English</span>
                <div className="flex justify-center">
                  {!!account && (
                    <div className={`${styles.iconSearch} lg:hidden`} onClick={() => setShowSearch(!showSearch)}>
                      <Image src="/images/icon/search.svg" alt="" />
                    </div>
                  )}
                  <div className={`md:hidden ${styles.accountMobile}`}>
                    <AccountButton
                      account={account}
                      accountLink={accountLink}
                      organization={organization}
                      businessUnitLink={businessUnitLink}
                    />
                  </div>
                  <CartButton cartLink={cartLink} organization={organization} />
                  <div className={`hamburgerBtn`} onClick={handleMenuActive}>
                    <div className={`hamburger md:hidden`}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="cart-quickOrder">
          <div className="">
            {!!account && (
              <>
                {/**<WishListButton wishlistItemCount={wishlistItemCount} wishlistLink={wishlistLink} /> **/}
                {/**<FlyingCartButton />**/}
              </>
            )}
          </div>
        </div>
        <nav aria-label="Top" className="hidden">
          {/* Secondary navigation */}
          <div className="h-full">
            <div className="top2">
              {!!account && (
                <div className="">
                  <button type="button" className="" onClick={() => setOpen(!open)}>
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}

              {/* Mega menus */}
              {!!account && (
                <Popover.Group className="">
                  <div className="">
                    {links.map((link, id) => (
                      <ReferenceLink key={id} target={link.reference} className="">
                        <Typography>{link.name}</Typography>
                      </ReferenceLink>
                    ))}
                  </div>
                </Popover.Group>
              )}
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
