import React, { HTMLAttributes } from 'react';
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';

type Props = {
  order: string;
  docType: string;
  title: string
};

const BicOrderDownloadDocument: React.FC<Props & HTMLAttributes<HTMLButtonElement>> = ({ order, docType, title }) => {
    const download = (event: React.MouseEvent) => {
        fetchApiHub('/action/bic-order-download-document/download', { method: 'POST' }, {order: order, docType: docType})
        .then((response) => {
              const linkSource = `data:application/pdf;base64,${response.file}`;
              const downloadLink = document.createElement("a");
              const fileName = response.filename;
          
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
            }
        )
    }
  
  return (
    <button className="flex flex-row items-center" title={title} onClick={download}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="h-4 w-4"><path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>
    </button>
  );
};

export default BicOrderDownloadDocument;
