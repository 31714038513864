import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import toast from 'react-hot-toast';
import Address from 'components/commercetools-ui/adyen-one-step-checkout/panels/address';
import Checkout from 'components/commercetools-ui/adyen-one-step-checkout/panels/checkout';
import Overview from 'components/commercetools-ui/adyen-one-step-checkout/panels/overview';
import OrderSummary from 'components/commercetools-ui/cart/orderSummary';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import { useBusinessUnitStateContext } from 'frontastic/provider/BusinessUnitState';
import { cart } from 'helpers/mocks/mockData';

export type FormData = {
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  shippingStreetName: string;
  shippingCity: string;
  shippingPostalCode: string;
  shippingCountry: string;
  shippingRegion: string;
  shippingFax: string;
  shippingMobile: string;
  shippingPOBox: string;
  shippingApartment: string;
  shippingBuilding: string;
  shippingDepartment: string;
  shippingCompany: string;
  shippingTitle: string;
  shippingCustom: any;
  billingStreetName: string;
  billingCity: string;
  billingPostalCode: string;
  billingCountry: string;
  billingRegion: string;
  billingFax: string;
  billingMobile: string;
  billingPOBox: string;
  billingApartment: string;
  billingBuilding: string;
  billingDepartment: string;
  billingCompany: string;
  billingTitle: string;
  billingCustom: any;
};

const AdyenOneStepCheckout = ({ termsLink, cancellationLink, privacyLink }) => {
  const { data: cartList, setShippingMethod, orderCart } = useCart();
  const { formatMessage } = useFormat({ name: 'cart' });
  const router = useRouter();
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const containerRef = useRef();
  const [billingIsSameAsShipping, setBillingIsSameAsShipping] = useState<boolean>(false);
  const [currentShippingMethod, setCurrentShippingMethod] = useState<ShippingMethod>();
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [isQuoteRequest, setIsQuoteRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { businessUnit } = useBusinessUnitStateContext();

  const inputExepectedDeliveryDateRef = useRef(null);
  const inputPoNumberRef = useRef(null);

  const [data, setData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    shippingStreetName: '',
    shippingCity: '',
    shippingPostalCode: '',
    shippingCountry: '',
    shippingRegion: '',
    shippingFax: '',
    shippingMobile: '',
    shippingPOBox: '',
    shippingApartment: '',
    shippingBuilding: '',
    shippingDepartment: '',
    shippingCompany: '',
    shippingTitle: '',
    shippingCustom: [],
    billingStreetName: '',
    billingCity: '',
    billingPostalCode: '',
    billingCountry: '',
    billingRegion: '',
    billingFax: '',
    billingMobile: '',
    billingPOBox: '',
    billingApartment: '',
    billingBuilding: '',
    billingDepartment: '',
    billingCompany: '',
    billingTitle: '',
    billingCustom: [],
  });

  const [payment, setPayment] = useState<any>({});

  const toggleBillingAddressOption = () => {
    setBillingIsSameAsShipping(!billingIsSameAsShipping);
  };

  const handleOrder = async () => {
    try {
      setIsLoading(true);
      const order = await orderCart(payment);
      router.replace(
        {
          pathname: '/thank-you',
          query: !!order.state ? { requireApproval: true, id: order.orderId } : { id: order.orderId },
        },
        undefined,
        {
          shallow: false,
        },
      );
    } catch (e) {
      toast.error(e?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const submitCheckout = () => {
    const dateIsValid = inputExepectedDeliveryDateRef.current.reportValidity();
    const poNumberIsValid = inputPoNumberRef.current.reportValidity();

    if (true === dateIsValid && true === poNumberIsValid) {
      handleOrder();
    }

    return;
  };

  const updateData = (data: FormData) => {
    setData(data);
  };

  const updatecurrentShippingMethod = (shippingMethod: ShippingMethod) => {
    if (shippingMethod?.shippingMethodId) {
      setCurrentShippingMethod(shippingMethod);
      setShippingMethod(shippingMethod.shippingMethodId);
    }
  }
  
  const updateDeliveryDate = (deliveryDate: string) => {
    setDeliveryDate(deliveryDate);
  }

  const submitButtonLabel = [
    formatMessage({ id: 'gotToShipping', defaultMessage: 'Go to shipping' }),
    formatMessage({ id: 'goToOverview', defaultMessage: 'Go to overview & payment' }),
    formatMessage({ id: 'ContinueAndPay', defaultMessage: 'Place order' }),
  ];

  useEffect(() => {
    if (cartList?.origin === 'Quote') {
      setIsQuoteRequest(true);
    }
  }, [cartList]);

  /*useEffect(() => {
    const defaultData = mapToFormStructure(cartList);
    if (defaultData && requiredDataIsValid(defaultData)) {
      updateData(defaultData);
    }
  }, [cartList]);*/

  useEffect(() => {
    if (!currentShippingMethod && cartList?.availableShippingMethods) {
      if (cartList?.shippingInfo) {
        const currentShippingMethod = cartList.availableShippingMethods.find(
          ({ shippingMethodId }) => shippingMethodId == cartList.shippingInfo.shippingMethodId,
        );
        setCurrentShippingMethod(currentShippingMethod);
      } else {
        setCurrentShippingMethod(cartList?.availableShippingMethods?.[0]);
      }
    }
  }, [cartList?.availableShippingMethods]);

  const billingAddressIsValid = () : boolean => {
    return (
      (null !== cartList.billingAddress)
      && (cartList.billingAddress.country ?? '').length !== 0
      && (cartList.billingAddress.lastName ?? '').length !== 0
      && (cartList.billingAddress.streetName ?? '').length !== 0
      && (cartList.billingAddress.streetName ?? '').length !== 0
    );
  }

  const shippingAddressIsValid = () : boolean => {
    return (
      (null !== cartList.shippingAddress)
      && (cartList.shippingAddress.country ?? '').length !== 0
      && (cartList.shippingAddress.lastName ?? '').length !== 0
      && (cartList.shippingAddress.streetName ?? '').length !== 0
    );
  }

  return (
    <div className="lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16" ref={containerRef}>
      <div className="sm:col-span-7 sm:p-6 lg:col-span-12 lg:mt-0 lg:p-8">
        <div className="border">
          <h2 className="ml-6 mt-4 text-lg font-bold">1. Address Details</h2>
          <Address
            data={data}
            updateData={updateData}
            billingIsSameAsShipping={billingIsSameAsShipping}
            toggleBillingAddressOption={toggleBillingAddressOption}
          />
        </div>
      </div>
      <div className="sm:col-span-8 sm:p-6 lg:col-span-12 lg:mt-0 lg:p-8">
        <div className="border">
          <h2 className="ml-6 mt-4 text-lg font-bold">2. Choose Shipping</h2>
          <Overview
            shippingMethods={cartList?.availableShippingMethods}
            currentShippingMethod={currentShippingMethod}
            onSelectShippingMethod={updatecurrentShippingMethod}
            onDeliveryDateChange={updateDeliveryDate}
            inputExepectedDeliveryDateRef={inputExepectedDeliveryDateRef}
          />
        </div>
      </div>
      <div className="sm:col-span-8 sm:p-6 lg:col-span-12 lg:mt-0 lg:p-8">
        <div className="border">
          <h2 className="ml-6 mt-4 text-lg font-bold">3. Payment</h2>
          <Checkout onPaymentUpdate={setPayment} expectedDeliveryDate={deliveryDate} inputPoNumberRef={inputPoNumberRef} />
        </div>
        <div className="mt-10 sm:col-span-8 sm:p-6 lg:col-span-12 lg:mt-0 lg:p-8">
          <OrderSummary
            cart={cartList}
            submitButtonLabel={submitButtonLabel[2]}
            disableSubmitButton={ !billingAddressIsValid() || !shippingAddressIsValid() || isLoading}
            displayAddressIsMissing={ !billingAddressIsValid() || !shippingAddressIsValid() }
            showDiscountsForm={false}//!isQuoteRequest}
            showSubmitButton={true}
            submitLoading={isLoading}
            onSubmit={submitCheckout}
            termsLink={termsLink}
            cancellationLink={cancellationLink}
            privacyLink={privacyLink}
          />
        </div>
      </div>
    </div>
  );
};

export default AdyenOneStepCheckout;
