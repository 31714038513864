/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import NextLink from 'next/link';
// eslint-disable-next-line import/no-unresolved
import { Product } from '@Types/product/Product';
// eslint-disable-next-line import/no-unresolved
import { CurrencyHelpers } from 'helpers/currencyHelpers';
// eslint-disable-next-line import/no-unresolved
import { useAvailability } from 'helpers/hooks/useAvailability';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import AddToCartButton from '../product-details/add-to-cart-button';

const Tile: React.FC<Product> = ({ variants, name, _url }) => {
  const { availableQuantity } = useAvailability(variants?.[0]);
  const [count, setCount] = useState(1);
  const { data: cart } = useCart();

  const addedToCart = () => {
    setCount(1);
  };

  return (
    <div className="text-center">
      <NextLink href={_url}>
        <a className="relative w-full">
          <div className="img">
            <Image
              src={variants[0].images[0]}
              alt={name}
              className="w-full object-contain group-hover:opacity-75"
            />
          </div>
          <div>
            <p className="mt-4 text-2xl font-semibold">
              {CurrencyHelpers.formatForCurrency(variants[0].price)}
            </p>
            <h3 className="swiper-slide-title mt-2">{name}</h3>
          </div>
        </a>
      </NextLink>
      <AddToCartButton
        disabled={
          !cart?.isPreBuyCart &&
          (count > availableQuantity || availableQuantity <= 0 || !variants?.[0].isOnStock)
        }
        quantity={count}
        variant={variants[0]}
        onAddedToCart={addedToCart}
      />
    </div>
  );
};

export default Tile;
