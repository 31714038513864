import React from 'react';
//import LanguageSwitcher from 'components/commercetools-ui/language-switcher';
import Typography from 'components/commercetools-ui/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import Column, { Link, ColumnType } from './column';
import { ReferenceLink } from 'helpers/reference';
export interface Props {
  logo: any;
  columns: ColumnType[];
  copyright?: string;
  copyrightLinks?: Link[];
}

const Footer: React.FC<Props> = ({ logo, columns, copyright, copyrightLinks }) => {
  const { formatMessage } = useFormat();

  return (
    <footer className="bic-footer" aria-labelledby="footer-heading">
      <div className="mx-auto w-full px-6 dark:bg-transparent lg:px-0">
        <div className="mx-auto max-w-5xl py-10 xl:grid xl:grid-cols-2">
          <div
            className={`grid grid-cols-1 gap-10 md:gap-4 md:grid-cols-${(
              columns.length + 1 
            ).toString()} xl:col-span-2`}
          >
            {logo && (
              <div className="md:col-start-1 md:col-end-5 lg:col-start-1 lg:col-end-1">
                <div className="footer-logo">
                  <Image media={logo.media ? logo.media : { media: {} }} />
                </div>
              </div>
            )}
            {columns?.map((column, index) => (
              <div key={index} className="md:flex md:justify-center">
                <Column column={column} />
              </div>
            ))}
            {/*<div className="justify-start md:justify-center">
              <div className="flex space-x-2 md:justify-start">
                <h3 className="font-medium text-neutral-900 dark:text-light-100">
                  <Typography>{formatMessage({ id: 'language', defaultMessage: 'Language' })}</Typography>
                </h3>
              </div>
              <LanguageSwitcher className="mt-3" />
            </div>*/}
          </div>
        </div>
      </div>
      {copyright && (
        <div className="copyright md:flex place-content-even border-t border-accent-500 p-4 sm:px-6">
          <div className="flex flex-wrap place-content-between md:place-content-around mt-2 md:mt-0 w-full">
            <p className="w-full px-2 text-xs font-medium text-neutral-900 sm:text-sm md:w-auto">{copyright}</p>
            {copyrightLinks?.map((item, i) => (
              <div key={i} className="text-xs sm:text-sm px-2 mt-2 md:mt-0">
                <ReferenceLink
                  target={item.reference}
                  className="font-medium text-neutral-900 hover:underline dark:text-light-100"
                >
                  <Typography>{item.name}</Typography>
                </ReferenceLink>
              </div>
            ))}
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
