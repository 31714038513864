import Image from 'frontastic/lib/image';
import styles from './index.module.scss';
import { mode } from 'tailwind.config';

const FullWidthSliderItem = ({ image, titleText, descriptionText, buttonLabel, buttonUrl, bannerUrl }) => {
  if (!image) {
    return (
      <div className="banner-text">
        <h3 className="title">{titleText}</h3>
        <p className="desc">{descriptionText}</p>
        <a href={buttonUrl}>{buttonLabel}</a>
      </div>
    );
  } else if (bannerUrl){
    return (
      <a href={bannerUrl}>
        <Image src={image.media.file} alt={titleText} className={`${styles.swiperImg}`} />
        <div className={`container flex flex-col justify-center ${styles.sliderContent}`}>
          <h2 className="title">{titleText}</h2>
          <p className="desc">{descriptionText}</p>
          <a href={buttonUrl}>{buttonLabel}</a>
        </div>
      </a>
    );
  } else {
    return (
      <>
        <Image src={image.media.file} alt={titleText} className={`${styles.swiperImg}`} />
        <div className={`container flex flex-col justify-center ${styles.sliderContent}`}>
          <h2 className="title">{titleText}</h2>
          <p className="desc">{descriptionText}</p>
          <a href={buttonUrl}>{buttonLabel}</a>
        </div>
      </>
    );
  }
};

export default FullWidthSliderItem;
