import { FC, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { updateURLParams, URLParam } from 'helpers/utils/updateURLParams';
import AvailabilityFilterDisclosure from './AvailabilityFilterDisclosure';
import CategoriesDisclosure from './CategoriesDisclosure';
import CheckboxDisclosure from './CheckboxDisclosure';
import PriceFilterDisclosure from './PriceFilterDisclosure';
import PublishedDisclosure from './PublishedDisclosure';
import SortingDisclosure from './SortingDisclosure';

type FiltersProps = {
  facets: Facet[];
  products: Product[];
  closeFiltering: any;
};

const Filters: FC<FiltersProps> = ({ facets, products, closeFiltering }) => {

  const [isTabletSize] = useMediaQuery(tablet);
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'product' });
  const [priceFilteringParams, setPriceFilteringParams] = useState<URLParam[]>([]);
  const [availabilityFilteringParams, setAvailabilityFilteringParams] = useState<URLParam[]>([]);
  const [publishedParams, setPublishedParams] = useState<URLParam[]>();
  const [categoriesParams, setCategoriesParams] = useState<URLParam[]>();
  const [checkboxParams, setCheckboxParams] = useState<URLParam[]>([]);
  const [sortingParam, setSortingParam] = useState<URLParam>();

  const isPublishedFacetAvailable = facets.some((facet) => facet.identifier === 'published');
  const availabilityFacet = facets?.find(({ identifier }) => identifier === 'variants.availability.availableQuantity');
  const isColourFacetAvailable = facets?.find(({ identifier }) => identifier === 'variants.attributes.colour.key');
  const isCategoriesAvailable = facets.some((facet) => facet.identifier === 'categories.id');

  const updatePublished = (params: URLParam[]) => {
    setPublishedParams(params);
  };

  const updatePriceFilteringParams = (params: URLParam[]) => {
    setPriceFilteringParams(params);
  };

  const updateAvailabilityFilteringParams = (params: URLParam[]) => {
    setAvailabilityFilteringParams(params);
  };

  const updateSortingParams = (param: URLParam) => {
    setSortingParam(param);
  };

  const updateCategories = (params: URLParam[], onUseEffect?: boolean) => {
    setCategoriesParams(params);
    if (undefined === onUseEffect || false === onUseEffect) {
      params = params.concat(checkboxParams);
      const currentURL = updateURLParams(params);
      router.push(currentURL);
      isTabletSize ? null : closeFiltering();
    }
  };

  const updateParams = (params: URLParam[], facetKey: string) => {
    const tmpParams = checkboxParams.filter((item) => {
      return !item.key.startsWith('facets['+facetKey+'][terms]')
    })
    params = tmpParams.concat(params);
    setCheckboxParams(params);
    params = params.concat(categoriesParams);
    const currentURL = updateURLParams(params);
    router.push(currentURL);
    isTabletSize ? null : closeFiltering();
  };

  const handleFiltersSubmit = (e) => {
    e.preventDefault();
    const params = [
      {
        key: 'cursor',
        value: 'offset:0',
      },
    ];

    if (priceFilteringParams) {
      //params.push(...priceFilteringParams);
    }

    if (availabilityFilteringParams) {
      //params.push(...availabilityFilteringParams);
    }

    if (publishedParams) {
      //params.push(...publishedParams);
    }

    if (categoriesParams) {
      params.push(...categoriesParams);
    }

    if (checkboxParams) {
      params.push(...checkboxParams);
    }

    if (sortingParam) {
      //params.push(sortingParam);
    }

    const currentURL = updateURLParams(params);

    router.push(currentURL);
    isTabletSize ? null : closeFiltering();
  };

  const onCloseFiltering = () => {
    isTabletSize ? null : closeFiltering();
  };

  const facetsKeys = [
    'variants.attributes.brand',
    'variants.attributes.model',
    'variants.attributes.packaging',
    'variants.attributes.colour.key',
    'variants.attributes.ink_colour.key',
    'variants.attributes.ink_colour_if_assorted.key',
    'variants.attributes.lubricating_strip',
    'variants.attributes.pivoting_head',
    'variants.attributes.movable_blades',
    'variants.attributes.line_width',
    'variants.attributes.point_size',
    'variants.attributes.point_type',
    'variants.attributes.erasable',
    'variants.attributes.number_of_sku_by_outer',
    'variants.attributes.rechargeable',
    'variants.attributes.retractable',
  ];

  return (
    <form onSubmit={handleFiltersSubmit}>
      {false && <SortingDisclosure updateSortingParams={updateSortingParams} />}
      {isPublishedFacetAvailable && <PublishedDisclosure updatePublished={updatePublished} />}
      {isCategoriesAvailable && (
        <CategoriesDisclosure products={products} facets={facets} updateCategories={updateCategories} />
      )}
      {isColourFacetAvailable && (
        <div>
          {facetsKeys.map((fKey) => (
            <CheckboxDisclosure
              key={fKey}
              facetKey={fKey}
              products={products}
              facets={facets}
              updateParams={updateParams}
            ></CheckboxDisclosure>
          ))}
        </div>
      )}
      {false && !!availabilityFacet && (
        <AvailabilityFilterDisclosure
          products={products}
          facets={facets}
          availabilityFacet={availabilityFacet}
          updateAvailabilityFilteringParams={updateAvailabilityFilteringParams}
        />
      )}
      {false && (
        <PriceFilterDisclosure
          products={products}
          facets={facets}
          updatePriceFilteringParams={updatePriceFilteringParams}
        />
      )}
      <div className="mt-8 flex max-w-xs justify-between gap-3">
        <NextLink href={router?.asPath.split('?')[0] || ''}>
          <a className="btn-secondary" onClick={onCloseFiltering}>
            {formatMessage({ id: 'clear', defaultMessage: 'Clear' })}
          </a>
        </NextLink>
      </div>
    </form>
  );
};

export default Filters;
