import React, { useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import Image, { NextFrontasticImage } from 'frontastic/lib/image';
import { useBicAccount } from 'bic/helpers/hooks/useBicAccount';
import { Reference, ReferenceLink } from 'helpers/reference';
import Item from '../commercetools-ui/cart/item';
const { getData } = require('country-list');


export interface RegisterProps {
  logo?: NextFrontasticImage;
}
const { create } = useBicAccount();

const CustomerRegister: React.FC<RegisterProps> = ({ logo }) => {
  const loginLink :Reference = {link: "/login", type: 'link', openInNewWindow: false};
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  interface formDataType {[key:string]: FormDataEntryValue}
  const values: formDataType = {}
  
  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget as HTMLFormElement);
    formData.forEach((value, property:string) => values[property] = value);
    create({
      country: values.country.toString(),
      firstname: values.firstname.toString(),
      lastname: values.lastname.toString(),
      email: values.email.toString(),
      company: values.company.toString(),
      phone: values.phone.toString(),
      message: values.message.toString()
    }).then((Response) =>{
      if (200 == Response?.statusCode) {
        setSuccess(formatAccountMessage({ id: 'account.request.send', defaultMessage: 'Your account request was sent.' }))
        setError('')
      } else {
        setError(formatAccountMessage({ id: 'account.request.send', defaultMessage: 'Your account request was not sent.' }))
        console.error(Response);
        setSuccess('')
      }
      setLoading(false)
    })
    
  }

 return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="relative h-12 dark:invert">
            <Image {...logo} alt="Bic" layout="fill" objectFit="contain" />
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-light-100">
            {formatAccountMessage({ id: 'account.request', defaultMessage: 'Request an account' })}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {formatAccountMessage({ id: 'account.alreadyHave', defaultMessage: "Already have an account?" })}{' '}
            <ReferenceLink
              target={loginLink}
              className="font-medium text-accent-400 underline hover:text-accent-500"
            >
              {formatAccountMessage({ id: 'account.login.here', defaultMessage: 'Login here' })}
            </ReferenceLink>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow dark:bg-primary-200 sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={onSubmitHandler}>
              {success &&
                <div className="bg-green-100 border border-green-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                  <span className="block sm:inline text-center">{success}</span>
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3"></span>
                </div>
              }
              {error &&
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                  <span className="block sm:inline text-center">{error}</span>
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3"></span>
                </div>
              }
              <div>
                <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-light-100">
                  {formatMessage({ id: 'country', defaultMessage: 'country' })}
                </label>
                <div className="mt-1">
                  <select 
                    name="country"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    >
                    <option></option>
                    {
                      getData().map((country) => <option key={country.code}>{country.name}</option>)
                    }
                  </select>
                </div>
              </div>
              <div>
                <label htmlFor="firstname" className="block text-sm font-medium text-gray-700 dark:text-light-100">
                  {formatMessage({ id: 'firstName', defaultMessage: 'firstName' })}
                </label>
                <div className="mt-1">
                  <input
                    id="firstname"
                    name="firstname"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="lastname" className="block text-sm font-medium text-gray-700 dark:text-light-100">
                  {formatMessage({ id: 'lastName', defaultMessage: 'lastName' })}
                </label>
                <div className="mt-1">
                  <input
                    id="lastname"
                    name="lastname"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="company" className="block text-sm font-medium text-gray-700 dark:text-light-100">
                  {formatMessage({ id: 'company', defaultMessage: 'Company' })}
                </label>
                <div className="mt-1">
                  <input
                    id="company"
                    name="company"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-light-100">
                  {formatMessage({ id: 'emailAddress', defaultMessage: 'Email Address' })}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-light-100">
                  {formatMessage({ id: 'phone', defaultMessage: 'Phone' })}
                </label>
                <div className="mt-1">
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    autoComplete="phone"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-light-100">
                  {formatMessage({ id: 'message', defaultMessage: 'Message' })}
                </label>
                <div className="mt-1">
                  <textarea
                    id="message"
                    rows={5}
                    name="message"
                    autoComplete="phone"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-accent-400 py-2 px-4 text-sm font-medium text-white shadow-sm transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
                  disabled={loading}
                >
                  {formatAccountMessage({ id: 'submit', defaultMessage: 'Submit' })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerRegister;