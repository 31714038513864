import React, { useState, useRef, useEffect } from 'react';
import Image from 'next/image';
import Slider, { SliderProps } from 'components/commercetools-ui/slider';
// import { SwiperSlide } from 'swiper/react';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { smallMobile, mobile } from 'helpers/utils/screensizes';
import { UIProduct } from '.';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const ImageGallery: React.FC<{ product: UIProduct }> = ({ product }) => {
  const [isSmallMobileSize] = useMediaQuery(smallMobile);

  const sliderFixedMood: SliderProps = {
    slidesPerView: 1,
    arrows: isSmallMobileSize ? false : true,
    dots: isSmallMobileSize ? true : false,
    spaceBetween: 0,
    withThumbs: true,
    loop: true,
  };

  const sliderConfiguration: SliderProps = sliderFixedMood;

  return (
    <div className="bic product-detail-gallery">
      <Slider {...sliderConfiguration}>
        {product?.images.map((image, idx) => (
          <img src={image.src} alt="" key={idx} />
        ))}
      </Slider>
    </div>
  );
};
