import React from 'react';
import Typography from 'components/commercetools-ui/typography';
import { Reference, ReferenceLink } from 'helpers/reference';
import { renderIcon } from './renderIcon';

export interface Link {
  name: string;
  reference: Reference;
}

export interface ColumnType {
  icon?: string;
  header: string;
  links: Link[];
}

export interface Props {
  column: ColumnType;
}

const Column: React.FC<Props> = ({ column }) => {
  return (
    <div>
      <div className="flex space-x-2">
        <h3 className="dark:text-light-100 font-medium text-neutral-900">
          <Typography>{column.header}</Typography>
        </h3>
      </div>
      <ul role="list" className="mt-3 space-y-3 pr-6">
        {column.links.map((item, i) => (
          <li key={i} className="">
            <ReferenceLink
              target={item.reference}
              className="dark:text-light-100 font-light text-neutral-900 hover:underline"
            >
              <Typography>{item.name}</Typography>
            </ReferenceLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Column;
