import { useState, useEffect, useRef } from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/outline';
import { Category } from '@Types/product/Category';
import { Money } from '@Types/product/Money';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { StringHelpers } from 'helpers/stringHelpers';
import { desktop } from 'helpers/utils/screensizes';
import { ImageGallery } from './image-gallery';
import ProductDetailsBreadcrumbs from './product-details-breadcrumbs';
import VariantSelector from './variant-selector';
import { getLabel } from 'helpers/utils/attributesHelpers';
import { useRouter } from 'next/router';
import { useCart, useProducts } from 'frontastic';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export interface Props {
  isPreview: boolean;
  product: UIProduct;
  subscriptions?: Product[];
  configurableComponents?: Product[];
  productFeaturesAttributes: string[];
  variant: Variant;
  onChangeVariantIdx: (idx: number) => void;
}

export type UIProduct = {
  name: string;
  variants: Variant[];
  price: Money;
  images: UIImage[];
  tastes?: string[];
  description: string;
  details?: UIDetail[];
  categories?: Category[];
};
interface UIImage {
  id: string;
  src: string;
  alt: string;
}
export interface UISize {
  label: string;
  key: string;
}
interface UIDetail {
  name: string;
  items: string[];
}

interface AttributesResponse {
  body: {
    results: any;
  };
}

export default function ProductDetail({
  product,
  subscriptions,
  configurableComponents,
  variant,
  onChangeVariantIdx,
  productFeaturesAttributes,
  isPreview,
}: Props) {
  const { data: cart } = useCart();
  const [inventoryNone] = useState(cart?.inventoryMode == 'None');

  const [isDesktopSize] = useMediaQuery(desktop);
  const [activeTab, setActiveTab] = useState('tab1');
  const [added, setAdded] = useState<boolean>(false);
  const tabTitleRef = useRef(null);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const isAttributeAvailable = (attribute: string) => {
    return typeof variant.attributes[attribute] !== 'undefined';
  };

  const { attributes } = useProducts();

  const router = useRouter();
  const locale = router.locale;
  const getAttributeValue = (attribute: string) => {
    if (typeof variant.attributes[attribute] === 'boolean') {
      return variant.attributes[attribute] ? 'Yes' : 'No';
    }
    // implements getAttributValue for Array like colours
    if (typeof variant.attributes[attribute] === 'object' && Array.isArray(variant.attributes[attribute])) {
      return variant.attributes[attribute].map((e) => e.label).join(', ');
    }
    if (typeof variant.attributes[attribute] === 'object' && variant.attributes[attribute]?.label) {
      return variant.attributes[attribute].label;
    }
    return variant.attributes[attribute];
  };

  const handleTab1 = () => {
    if (!isDesktopSize && activeTab === 'tab1') {
      return setActiveTab('tab0');
    }

    document.querySelector('.bic-tabs').scrollIntoView({ behavior: 'smooth', block: 'start' });
    setActiveTab('tab1');
  };

  const handleTab2 = () => {
    if (!isDesktopSize && activeTab === 'tab2') {
      return setActiveTab('tab0');
    }

    document.querySelector('.bic-tabs').scrollIntoView({ behavior: 'smooth', block: 'start' });
    setActiveTab('tab2');
  };

  const handleTab3 = () => {
    if (!isDesktopSize && activeTab === 'tab3') {
      return setActiveTab('tab0');
    }

    document.querySelector('.bic-tabs').scrollIntoView({ behavior: 'smooth', block: 'start' });
    setActiveTab('tab3');
  };

  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false);
      }, 1000);
    }
  }, [added]);

  const productDesc = variant?.attributes?.long_description;
  let productDescNotEmpty;
  if (productDesc !== undefined) {
    productDescNotEmpty = productDesc.replace('<p>', '').replace('</p>', '');
  }

  useEffect(() => {
    if (productDesc !== undefined && productDescNotEmpty.trim() !== '') {
      setActiveTab('tab1');
    } else {
      setActiveTab('tab2');
    }
  }, [productDesc, productDescNotEmpty]);

  return (
    <div>
      <div className="product-detail-page mx-auto">
        <div>{!!product?.categories?.length && <ProductDetailsBreadcrumbs categories={product.categories} />}</div>
        {isPreview && (
          <span className="mt-2 inline-block rounded-sm bg-orange-300 p-2 text-sm font-light text-white">
            Displaying product in preview/staging mode.
          </span>
        )}
        <div className="product-details mt-2">
          {/* Image gallery */}
          <ImageGallery product={product} />

          {/* Product info */}
          <div className="product-info">
            <p className="product-brand">{variant?.attributes?.['brand']?.label}</p>
            <h1 className="product-name">{product?.name}</h1>
            <p className="product-sku">Sku: {product?.variants[0]?.sku}</p>
            {/*!inventoryNone && !variant?.isOnStock && (
              <div className="product-status out-of-stock">
                {formatProductMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' })}
              </div>
            )}
            {(inventoryNone || variant?.isOnStock) && (
              <div className="product-status in-stock">
                {formatProductMessage({ id: 'inStock', defaultMessage: 'In stock' })}
              </div>
            )*/}
            {!!variant.attributes?.['manufacturer-number'] && (
              <p className="mt-4">{`Manufacturer Part: ${variant.attributes['manufacturer-number']}`}</p>
            )}

            <form className="product-add-to-cart-form lg:mt-[60px]">
              {!!variant.attributes?.narcotic && !!variant.attributes?.['product-alert-text'] && (
                <div>
                  <p className="text-sm text-red-500">{variant.attributes?.['product-alert-text']}</p>
                </div>
              )}
              <div className="flex sm:flex-1">
                <VariantSelector
                  className="mt-4"
                  subscriptions={subscriptions}
                  configurableComponents={configurableComponents}
                  product={product}
                  onChangeVariantIdx={onChangeVariantIdx}
                  variant={variant}
                  hideAvailability={inventoryNone}
                  hideWishlistButton={true}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="bic-tabs">
          <div className="flex">
            {productDesc !== undefined && productDescNotEmpty.trim() !== '' && (
              <div
                className={`tab-title hidden lg:block ${activeTab === 'tab1' ? 'is-open' : ''}`}
                onClick={handleTab1}
              >
                <span>{formatProductMessage({ id: 'product.description', defaultMessage: 'Description' })}</span>
              </div>
            )}
            <div className={`tab-title hidden lg:block ${activeTab === 'tab2' ? 'is-open' : ''}`} onClick={handleTab2}>
              <span>
                {formatProductMessage({
                  id: 'product.charateristics',
                  defaultMessage: 'Charateristics',
                })}
              </span>
            </div>
            {!!productFeaturesAttributes.filter(isAttributeAvailable).length && (
              <div
                className={`tab-title hidden lg:block ${activeTab === 'tab3' ? 'is-open' : ''}`}
                onClick={handleTab3}
              >
                <span>
                  {formatProductMessage({
                    id: 'product.delivery',
                    defaultMessage: 'Delivery',
                  })}
                </span>
              </div>
            )}
          </div>
          {productDesc !== undefined && productDescNotEmpty.trim() !== '' && (
            <section>
              <div className={`tab-title lg:hidden ${activeTab === 'tab1' ? 'is-open' : ''}`} onClick={handleTab1}>
                <span>{formatProductMessage({ id: 'product.description', defaultMessage: 'Description' })}</span>
                <span className="tab-title-icon"></span>
              </div>
              {activeTab === 'tab1' && (
                <div className="tab-content tab-product-description">
                  <div>{productDesc}</div>
                  <div>{getAttributeValue('key_selling_point_one')}</div>
                  <div>{getAttributeValue('key_selling_point_two')}</div>
                  <div>{getAttributeValue('key_selling_point_three')}</div>
                  <div>{getAttributeValue('key_selling_point_four')}</div>
                  <div>{getAttributeValue('key_selling_point_five')}</div>
                  <div>{getAttributeValue('key_selling_point_six')}</div>
                  <div>{getAttributeValue('key_selling_point_seven')}</div>
                  {/* Hide this download link since its backend would not be done for the initial launch */}
                  {/* <div className="download-link mt-8">
                    <a href="#">
                      {formatProductMessage({
                        id: 'product.download',
                        defaultMessage: 'Télécharger les caractéristiques',
                      })}
                    </a>
                  </div> */}
                </div>
              )}
            </section>
          )}
          {!!productFeaturesAttributes.filter(isAttributeAvailable).length && (
            <section>
              <div className={`tab-title lg:hidden ${activeTab === 'tab2' ? 'is-open' : ''}`} onClick={handleTab2}>
                <span>
                  {formatProductMessage({
                    id: 'product.charateristics',
                    defaultMessage: 'Charateristics',
                  })}
                </span>
                <span className="tab-title-icon"></span>
              </div>
              {activeTab === 'tab2' && (
                <div className="tab-content tab-product-characteristics">
                  <div className="tab-product-features">
                    {productFeaturesAttributes.filter(isAttributeAvailable).map((attribute) => (
                      <p key={attribute} className="">
                        <span className="mr-2 font-semibold">{getLabel(attribute, locale)}:</span>
                        <span className="">{getAttributeValue(attribute)}</span>
                      </p>
                    ))}
                  </div>
                  {false && (
                    <div className="tab-product-attributes-all">
                      <section aria-labelledby="details-heading" className="mt-12 border-t">
                        <div className="mt-6">
                          <h3 className="text-xl font-bold">All Attributes</h3>
                          <div>
                            {Object.keys(variant?.attributes).map((attribute) => (
                              <div key={attribute} className=" pb-2">
                                <span className="mr-2 font-semibold">{getLabel(attribute, locale)}: </span>
                                <span className="">{getAttributeValue(attribute)}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </section>
                    </div>
                  )}
                  {/* {Object.keys(variant?.attributes).map((attribute) => (
                    <div key={attribute} className=" pb-2">
                      <span className="mr-2 font-semibold">{`${StringHelpers.capitaliseFirstLetter(attribute)}:`}</span>
                      <span className="">{getAttributeValue(attribute)}</span>
                    </div>
                  ))} */}
                </div>
              )}
            </section>
          )}

          {!!productFeaturesAttributes.filter(isAttributeAvailable).length && (
            <section>
              <div
                className={`tab-title lg:hidden ${activeTab === 'tab3' ? 'is-open' : ''}`}
                onClick={handleTab3}
                ref={tabTitleRef}
              >
                <span>
                  {formatProductMessage({
                    id: 'product.delivery',
                    defaultMessage: 'Delivery',
                  })}
                </span>
                <span className="tab-title-icon"></span>
              </div>
              {activeTab === 'tab3' && (
                <div className="tab-content">
                  <div className="table-container">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th colSpan={2} className="merge-th">
                            {formatProductMessage({ id: 'product.characteristic.palette', defaultMessage: 'Palette' })}
                          </th>
                        </tr>
                        <tr>
                          <th></th>
                          <th>{formatProductMessage({ id: 'product.characteristic.sku', defaultMessage: 'SKU' })}</th>
                          <th>
                            {formatProductMessage({ id: 'product.characteristic.outer', defaultMessage: 'Outer' })}
                          </th>
                          <th>
                            {formatProductMessage({ id: 'product.characteristic.byLayer', defaultMessage: 'By layer' })}
                          </th>
                          <th>{formatProductMessage({ id: 'product.characteristic.full', defaultMessage: 'Full' })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{formatProductMessage({ id: 'product.characteristic.moq', defaultMessage: 'MOQ' })}</td>
                          <td>{variant?.attributes.bic_min_sale_qty}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>{formatProductMessage({ id: 'product.characteristic.ean', defaultMessage: 'EAN' })}</td>
                          <td>{variant?.attributes.ean}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>{formatProductMessage({ id: 'product.characteristic.unit', defaultMessage: 'Unit' })}</td>
                          <td>{variant?.attributes.number_of_each_by_sku}</td>
                          <td>{variant?.attributes.number_of_each_by_outer_pack}</td>
                          <td>{variant?.attributes.number_of_each_by_layer}</td>
                          <td>{variant?.attributes.number_of_each_by_pallet}</td>
                        </tr>
                        <tr>
                          <td>{formatProductMessage({ id: 'product.characteristic.sku', defaultMessage: 'SKU' })}</td>
                          <td></td>
                          <td>{variant?.attributes.number_of_sku_by_outer}</td>
                          <td>{variant?.attributes.number_of_sku_by_layer}</td>
                          <td>{variant?.attributes.number_of_sku_by_pallet}</td>
                        </tr>
                        <tr>
                          <td>
                            {formatProductMessage({ id: 'product.characteristic.outer', defaultMessage: 'Outer' })}
                          </td>
                          <td></td>
                          <td></td>
                          <td>{variant?.attributes.number_of_outer_by_layer}</td>
                          <td>{variant?.attributes.number_of_outer_by_pallet}</td>
                        </tr>
                        <tr>
                          <td>
                            {formatProductMessage({ id: 'product.characteristic.layer', defaultMessage: 'Layer' })}
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{variant?.attributes.number_of_layers_by_pallet}</td>
                        </tr>
                        <tr>
                          <td>
                            {formatProductMessage({ id: 'product.characteristic.width', defaultMessage: 'Width (Cm)' })}
                          </td>
                          <td>{variant?.attributes.profondeur_uc}</td>
                          <td>{variant?.attributes.outer_width}</td>
                          <td></td>
                          <td>{variant?.attributes.pallet_width}</td>
                        </tr>
                        <tr>
                          <td>
                            {formatProductMessage({
                              id: 'product.characteristic.height',
                              defaultMessage: 'Height (Cm)',
                            })}
                          </td>
                          <td>{variant?.attributes.hauteur_uc}</td>
                          <td>{variant?.attributes.outer_height}</td>
                          <td></td>
                          <td>{variant?.attributes.pallet_height}</td>
                        </tr>
                        <tr>
                          <td>
                            {formatProductMessage({
                              id: 'product.characteristic.length',
                              defaultMessage: 'Length (Cm)',
                            })}
                          </td>
                          <td>{variant?.attributes.largeur_uc}</td>
                          <td>{variant?.attributes.outer_length_depth}</td>
                          <td></td>
                          <td>{variant?.attributes.pallet_length_depth}</td>
                        </tr>
                        <tr>
                          <td>
                            {formatProductMessage({
                              id: 'product.characteristic.volume',
                              defaultMessage: 'Volume ',
                            })}
                            (Dm<sup>3</sup>)
                          </td>
                          <td>{variant?.attributes.volume_uc}</td>
                          <td>{variant?.attributes.outer_volume}</td>
                          <td></td>
                          <td>{variant?.attributes.pallet_volume}</td>
                        </tr>
                        <tr>
                          <td>
                            {formatProductMessage({
                              id: 'product.characteristic.netWeight',
                              defaultMessage: 'Net weight (Gr)',
                            })}
                          </td>
                          <td>{variant?.attributes.weight}</td>
                          <td>{variant?.attributes.outer_weight}</td>
                          <td></td>
                          <td>{variant?.attributes.pallet_weight}</td>
                        </tr>
                        <tr>
                          <td>
                            {formatProductMessage({
                              id: 'product.characteristic.grossWeight',
                              defaultMessage: 'Gross weight (Gr)',
                            })}
                          </td>
                          <td>{variant?.attributes.gross_weight}</td>
                          <td>{variant?.attributes.outer_gross_weight}</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* <div>
                    {productFeaturesAttributes.filter(isAttributeAvailable).map((attribute) => (
                      <div key={attribute}>
                        <span className="mr-2 font-semibold">{`${StringHelpers.capitaliseFirstLetter(
                          attribute,
                        )}:`}</span>
                        <span className="">{getAttributeValue(attribute)}</span>
                      </div>
                    ))}
                  </div> */}
                </div>
              )}
            </section>
          )}
        </div>

        {product?.details?.length > 0 && (
          <section className="mt-12 border-t">
            <div className="divide-y divide-gray-200 border-t">
              {product?.details?.map((detail) => (
                <Disclosure key={detail.name}>
                  {({ open }) => (
                    <>
                      <h3>
                        <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                          <span
                            className={classNames(
                              open ? 'text-accent-400' : 'text-gray-900 dark:text-light-100',
                              'text-sm font-medium',
                            )}
                          >
                            {detail.name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusSmIcon
                                className="block h-6 w-6 text-accent-400 group-hover:text-accent-500"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusSmIcon
                                className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel>
                        <div className="prose prose-sm py-6 dark:text-light-100">
                          <ul role="list">
                            {detail.items?.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          </section>
        )}
      </div>
    </div>
  );
}
