import { UseBicAccount } from 'bic/frontastic/provider/Frontastic/UseBicAccount';
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';

export interface CreateBicAccount {
  email: string;
  firstname: string;
  lastname: string;
  company: string;
  phone: string;
  message: string;
  country: string;
}

export const useBicAccount = (): UseBicAccount => {
  const create = async (account: CreateBicAccount): Promise<any> => {
    return fetchApiHub('/action/bic/requestAccount', { method: 'POST' }, account);
  };
  return { create };
};
