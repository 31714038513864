import React, { useState } from 'react';
import NextLink from 'next/link';
import Typography from 'components/commercetools-ui/typography';
import Image from 'frontastic/lib/image';
import styles from './index.module.scss';

const MegaMenuContent = ({ category, categoryIdx, handleCloseSubNav, setActiveCategory }) => {
  const [isActivePanel, setActivePanel] = useState('0');
  const toggleItem = (index) => {
    if (isActivePanel === index) {
      return setActivePanel('0');
    }
    setActivePanel(index);
  };

  const handleSubNav = (categoryIdx) => {
    setActiveCategory(categoryIdx);
    handleCloseSubNav('0');
    document.getElementById('bicNavigation').classList.remove('bicNavigationOpen');
    document.documentElement.classList.remove('menu-open');
    document.querySelector('.hamburgerBtn').classList.remove('menuIsActive');
  };

  //i18n messages
  if (!category.subCategories?.length) {
    return null;
  }

  return (
    <>
      <div className={`${styles.arrowBtn} px-3 md:hidden`} onClick={() => handleCloseSubNav('0')}>
        <Image src="/images/icon/chevron.svg" alt="" />
        {category.name}
      </div>
      <div className="submenu p-4">
        {category.subCategories.map((subCategory, subCategoryIdx) => (
          <div key={subCategory.categoryId} className="submenu-group">
            <div className={`subMenu-mobile flex items-center ${isActivePanel === subCategoryIdx ? 'isShowing' : ''}`}>
              <NextLink href={subCategory.slug}>
                <div
                  id={`heading-${categoryIdx}`}
                  className={`subnav-heading flex w-full cursor-pointer font-bold `}
                  onClick={handleSubNav}
                >
                  {subCategory.name}
                </div>
              </NextLink>
              {subCategory?.subCategories?.length > 0 && (
                <div className={`arrowBtn-level-0 md:hidden`} onClick={() => toggleItem(subCategoryIdx)}>
                  <Image src="/images/icon/chevron.svg" alt="" />
                </div>
              )}
            </div>
            <ul role="list" aria-labelledby={`heading-${categoryIdx}`} className={`sub-nav-level-0`}>
              {subCategory?.subCategories?.map((item) => (
                <li key={item.name} className="" onClick={() => handleSubNav(categoryIdx)}>
                  <NextLink href={item.slug}>
                    <a className="block">
                      <Typography>{item.name}</Typography>
                    </a>
                  </NextLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <NextLink href={category.slug}>
        <div
          id={`heading-${categoryIdx}`}
          className={`subnav-heading flex w-full cursor-pointer font-bold `}
          style={{justifyContent: 'center'}}
          onClick={handleSubNav}
        >
          <button className="btn-primary mt-8 text-white font-bold py-2 px-4 rounded-full">
          See all products
        </button>
        </div>
        </NextLink>
      </div>
    </>
  );
};

export default MegaMenuContent;
