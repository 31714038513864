import React from 'react';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mediumDesktop } from 'helpers/utils/screensizes';
import ReactPaginate from 'react-paginate';

function PaginatedItems({ itemsPerPage, pageCount, onPageChange }) {
  const [isMediumDesktopSize] = useMediaQuery(mediumDesktop);
  const pageRangeDisplayed = isMediumDesktopSize ? 2 : 1;

  return (
    <>
      <ReactPaginate
        onPageChange={onPageChange}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        pageClassName="page-item"
        previousClassName="page-item page-prev"
        previousLinkClassName="page-link link-prev"
        nextClassName="page-item page-next"
        nextLinkClassName="page-link link-next"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
      />
    </>
  );
}

export default PaginatedItems;
