import { Cart } from '@Types/cart/Cart';
import { CartDetails } from 'frontastic/actions/cart';
import { FormData } from '.';

export const mapToCartStructure = (data: FormData, billingIsSameAsShipping: boolean): CartDetails => {
  const commonData = {
    firstName: data.firstName,
    lastName: data.lastName,
  };

  const mappedData: CartDetails = {
    account: {
      email: data.email,
    },
    shipping: {
      ...commonData,
      addressId: '1st-shipping-address',
      streetName: data.shippingStreetName,
      postalCode: data.shippingPostalCode,
      city: data.shippingCity,
      country: data.shippingCountry,
      region: data.shippingRegion,
      fax: data.shippingFax,
      mobile: data.shippingMobile,
      pOBox: data.shippingPOBox,
      apartment: data.shippingApartment,
      building: data.shippingBuilding,
      department: data.shippingDepartment,
      company: data.shippingCompany,
      title: data.shippingTitle,
      custom: data.shippingCustom,
    },
  };

  if (true) {
    mappedData.billing = {
      ...commonData,
      addressId: '1st-billing-address',
      streetName: data.billingStreetName,
      postalCode: data.billingPostalCode,
      city: data.billingCity,
      country: data.billingCountry,
      region: data.billingRegion,
      fax: data.billingFax,
      mobile: data.billingMobile,
      pOBox: data.billingPOBox,
      apartment: data.billingApartment,
      building: data.billingBuilding,
      department: data.billingDepartment,
      company: data.billingCompany,
      title: data.billingTitle,
      custom: data.billingCustom,
    };
  }

  return mappedData;
};

export const mapToFormStructure = (data: Cart): FormData => {
  if (!data?.shippingAddress) return;

  const mappedData: FormData = {
    firstName: data.shippingAddress.firstName,
    lastName: data.shippingAddress.lastName,
    email: data.email,
    shippingStreetName: data.shippingAddress.streetName,
    shippingCity: data.shippingAddress.city,
    shippingPostalCode: data.shippingAddress.postalCode,
    shippingCountry: data.shippingAddress.country,
    shippingRegion: data.shippingAddress.region,
    shippingFax: data.shippingAddress.fax,
    shippingMobile: data.shippingAddress.mobile,
    shippingPOBox: data.shippingAddress.pOBox,
    shippingApartment: data.shippingAddress.apartment,
    shippingBuilding: data.shippingAddress.building,
    shippingDepartment: data.shippingAddress.department,
    shippingCompany: data.shippingAddress.company,
    shippingTitle: data.shippingAddress.title,
    shippingCustom: data.shippingAddress.custom,
    billingStreetName: data.billingAddress.streetName,
    billingCity: data.billingAddress.city,
    billingPostalCode: data.billingAddress.postalCode,
    billingCountry: data.billingAddress.country,
    billingRegion: data.billingAddress.region,
    billingFax: data.billingAddress.fax,
    billingMobile: data.billingAddress.mobile,
    billingPOBox: data.billingAddress.pOBox,
    billingApartment: data.billingAddress.apartment,
    billingBuilding: data.billingAddress.building,
    billingDepartment: data.billingAddress.department,
    billingCompany: data.billingAddress.company,
    billingTitle: data.billingAddress.title,
    billingCustom: data.billingAddress.custom,
  };

  return mappedData;
};
