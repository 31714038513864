import React, { useState } from 'react';
import NextLink from 'next/link';
import { Product } from '@Types/product/Product';
import WishlistButton from 'components/commercetools-ui/wishlist-button';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useAvailability } from 'helpers/hooks/useAvailability';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import AddToCartButton from '../product-details/add-to-cart-button';
import { useBusinessUnitStateContext } from 'frontastic/provider/BusinessUnitState';

interface Props {
  product: Product;
  isPreview?: boolean;
  previewURL?: string;
}

const ListItem: React.FC<Props> = ({ product, isPreview, previewURL }) => {
  const { businessUnit } = useBusinessUnitStateContext();
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { data: cart } = useCart();
  const { availableQuantity } = useAvailability(product?.variants?.[0]);
  const [inventoryNone] = useState(cart?.inventoryMode == 'None');

  const qtyMode = businessUnit.custom.fields.quantityMode ?? "sku";

  const [step] = useState(qtyMode === "sku" ? parseInt(product.variants[0]?.attributes['bic_qty_increment'] || '1') : parseInt(product.variants[0]?.attributes['number_of_sku_by_outer'] || '1'));
  const [min] = useState(qtyMode === "sku" ?  parseInt(product.variants[0]?.attributes['bic_min_sale_qty'] || step) : parseInt(product.variants[0]?.attributes['number_of_sku_by_outer'] || '1'));
  const [max] = useState(qtyMode === "sku" ?  parseInt(product.variants[0]?.attributes['bic_max_sale_qty'] || 999999999) : 999999999);
  const [count, setCount] = useState(Number(min));

  const addedToCart = () => {
    setCount(min);
  };

  return (
    <div className="product-item mb-16 text-center">
      <NextLink href={isPreview ? `${previewURL}${product._url}` : product._url}>
        <a className="group">
          <div className="product-item-img mb-9">
            <Image
              src={product.variants[0].images[0]}
              alt={product.name}
              className="mx-auto object-contain object-center"
            />
          </div>
          <div className="product-item-price">
            {product?.variants[0]?.discountedPrice && (
              <div className="price">{CurrencyHelpers.formatForCurrency(product?.variants[0]?.discountedPrice)}</div>
            )}
            <div className={`${product?.variants[0]?.discountedPrice ? 'old-price' : ''}`}>
              {CurrencyHelpers.formatForCurrency(product.variants[0].price)}
            </div>
          </div>
        </a>
      </NextLink>
      <div className="product-item-bottom">
        <form className="product-add-to-cart-form" action="#">
          <NextLink href={isPreview ? `${previewURL}${product._url}` : product._url}>
            <a className="product-item-name mt-2" title={product.name}>
              {product.name}
            </a>
          </NextLink>
          <div className={'product-item-sku'} data-sku={product.variants[0].sku}>
            Sku: {product.variants[0].sku}
          </div>
          {!inventoryNone && (!availableQuantity || availableQuantity <= 0) && !cart?.isPreBuyCart && (
            <div className="mt-4 flex items-center justify-center text-sm">
              {formatProductMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' })}
            </div>
          )}

          {(inventoryNone || availableQuantity > 0) && !cart?.isPreBuyCart && (
            <div className="mt-4 flex items-center justify-center">
              <div className="product-qty-wrapper mr-2">
                <input
                  type="number"
                  className="product-qty-input"
                  onChange={(e) => setCount(Number(e.target.value))}
                  value={count}
                  step={step}
                  min={min}
                  max={max}
                  disabled={!inventoryNone && !product.variants?.[0].isOnStock && !cart?.isPreBuyCart}
                ></input>
              </div>
              <div className="product-item-available-qty">{!inventoryNone && <span> /{availableQuantity}</span>} </div>
            </div>
          )}
          <div className="mt-3 flex justify-center">
            <AddToCartButton
              disabled={
                !cart?.isPreBuyCart &&
                !inventoryNone &&
                (count > availableQuantity || availableQuantity <= 0 || !product.variants?.[0].isOnStock)
              }
              quantity={count}
              variant={product.variants[0]}
              onAddedToCart={addedToCart}
            />
          </div>
          {/* <div className="mt-3 flex justify-center">
            <WishlistButton isCompact variant={product?.variants?.[0]} />
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default ListItem;
