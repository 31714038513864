import React, { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { HeartIcon } from '@heroicons/react/solid';
import { Variant } from '@Types/product/Variant';
import { Wishlist } from '@Types/wishlist/Wishlist';
import { useFormat } from 'helpers/hooks/useFormat';
import { useWishlist } from 'frontastic';
import WishlistButtonItem from './wishlist-button-item';
import styles from './wishlist-button.module.scss';
import WishlistNewButton from './wishlist-new-button';
export interface WishlistButtonProps {
  variant: Variant;
  isCompact?: boolean;
}

const WishlistButton: React.FC<WishlistButtonProps> = ({ variant, isCompact }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { storeWishlists, fetchStoreWishlists } = useWishlist();
  const [wishlists, setWishlists] = useState<Wishlist[]>([]);
  useEffect(() => {
    if (!!storeWishlists) {
      setWishlists(storeWishlists);
    }
  }, [storeWishlists]);

  return (
    <Popover className="wishlist-popup-wrapper relative">
      {({ open }) => (
        <>
          {!isCompact && (
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center px-3 py-3 font-bold text-primary-400 text-white`}
            >
              <HeartIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
            </Popover.Button>
          )}
          {isCompact && (
            <Popover.Button
              className={`
                ${open ? 'popover-open' : ''}
                btn-popover flex items-center font-bold`}
            >
              {formatProductMessage({ id: 'addToPurchaseList', defaultMessage: 'Add to purchase list' })}
            </Popover.Button>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={`wishlist-popup absolute left-1/2 z-10 mt-3 max-w-sm -translate-x-1/2 transform rounded-md bg-gray-300 px-2 pb-4 ${styles.popover}`}
            >
              {wishlists?.map((wishlist) => (
                <WishlistButtonItem
                  wishlist={wishlist}
                  key={wishlist.wishlistId}
                  sku={variant.sku}
                  onAddToWishlist={fetchStoreWishlists}
                />
              ))}
              <div className={`w-full ${wishlists?.length ? 'border-t-2' : ''}`}>
                <WishlistNewButton sku={variant.sku} onCreatedNewList={fetchStoreWishlists} />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default WishlistButton;
