import React from 'react';
import Login, { LoginProps } from 'bic/components/commercetools-ui/account/login';

export interface Props {
  data: LoginProps;
}

const BicAccountLoginTastic: React.FC<Props> = ({ data }) => {
  return <Login {...data} />;
};

export default BicAccountLoginTastic;
