import React, { useEffect, useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { BusinessUnit } from '@Types/business-unit/BusinessUnit';
import { LoadingIcon } from 'components/commercetools-ui/icons/loading';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';
import Image, { NextFrontasticImage } from 'frontastic/lib/image';
import { useBusinessUnitStateContext } from 'frontastic/provider/BusinessUnitState';

export interface LoginProps {
  logo?: NextFrontasticImage;
  registerLink?: Reference;
  accountLink?: Reference;
}

const Login: React.FC<LoginProps> = ({ logo, registerLink, accountLink }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });

  //account actions
  const { login, loggedIn, resendVerificationEmail, requestPasswordReset, isSuperUser } = useAccount();
  const { getSuperUserBusinessUnits } = useBusinessUnitStateContext();

  //login data
  const [data, setData] = useState({ email: '', password: '', rememberMe: false, businessUnitKey: '' });

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);
  const [isLoadingSuperUserBusinessUnits, setIsLoadingSuperUserBusinessUnits] = useState(false);

  //attempting to resend verification email
  const [resendVerification, setResendVerification] = useState(false);

  //attempting to request a password reset
  const [resendPasswordReset, setResendPasswordReset] = useState(false);

  const [superUserBusinessUnits, setSuperUserBusinessUnits] = useState([]);

  //not on default login modal
  const subModal = resendVerification || resendPasswordReset;

  // Password toggle
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  //get back to login modal
  const backToLogin = () => {
    setResendPasswordReset(false);
    setResendVerification(false);
  };

  //wants to resend verification
  const toResendVerification = () => {
    setResendVerification(true);
    setResendPasswordReset(false);
  };

  //requesting a password reset
  const toResendPassword = () => {
    setResendPasswordReset(true);
    setResendVerification(false);
  };

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //handle checkbox input change
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };

  //login user
  const loginUser = async () => {
    try {
      const response = await login(data.email, data.password, data.rememberMe, data.businessUnitKey);
      if (!response.accountId) {
        setError(formatErrorMessage({ id: 'auth.wrong', defaultMessage: 'Wrong email address or password' }));
      }
    } catch (err) {
      if (err.message !== 'superuser') {
        setError(err.message);
      } else {
        setError('Please login as Super User into an organization');
      }
    }
  };

  //resend verification email for user
  const resendVerificationEmailForUser = async () => {
    try {
      await resendVerificationEmail(data.email, data.password);
      setSuccess(
        formatAccountMessage({
          id: 'verification.resent',
          defaultMessage: 'An email was sent to {email}',
          values: { email: data.email },
        }),
      );
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  //request a password reset for user
  const resendPasswordResetForUser = async () => {
    try {
      await requestPasswordReset(data.email);
      setSuccess(
        formatAccountMessage({
          id: 'verification.resent',
          defaultMessage: 'An email was sent to {email}',
          values: { email: data.email },
        }),
      );
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //processing starts
    setLoading(true);
    //if user is attempting to resend verification email
    if (resendVerification) resendVerificationEmailForUser();
    //if user is attempting tor equest a password reset
    else if (resendPasswordReset) resendPasswordResetForUser();
    //if user wants to login
    else await loginUser();
    //processing ends
    setLoading(false);
  };

  useEffect(() => {
    if (isSuperUser) {
      setIsLoadingSuperUserBusinessUnits(true);
      getSuperUserBusinessUnits(data.email)
        .then((businessUnits) => setSuperUserBusinessUnits(businessUnits))
        .catch((error) => setError(error.message))
        .finally(() => setIsLoadingSuperUserBusinessUnits(false));
    }
  }, [isSuperUser]);

  if (loggedIn) return <Redirect target={accountLink} />;

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-light-100">
            {formatAccountMessage({ id: 'account.sign.in', defaultMessage: 'Sign in to your account' })}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {/*{formatAccountMessage({ id: 'account.doNotHave', defaultMessage: "Don't have an account?" })}{' '}
            <ReferenceLink
              target={registerLink}
              className="font-medium text-accent-400 underline hover:text-accent-500"
            >
              {formatAccountMessage({ id: 'account.ask.here', defaultMessage: 'Ask for an account here' })}
  </ReferenceLink>*/}
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow dark:bg-primary-200 sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              {success && <p className="text-sm text-green-600">{success}</p>}
              {error && <p className="text-sm text-accent-400">{error}</p>}
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-light-100">
                  {formatMessage({ id: 'emailAddress', defaultMessage: 'Email Address' })}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {!resendPasswordReset && (
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-light-100">
                    {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type={passwordShown ? "text" : "password"}
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="float-right -mt-8 mr-2">
                    <svg onClick={togglePassword} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                    </svg>
                  </div>
                </div>
              )}

              {isSuperUser && !isLoadingSuperUserBusinessUnits && !!superUserBusinessUnits.length && (
                <div>
                  <label
                    htmlFor="businessUnitKey"
                    className="block text-sm font-medium text-gray-700 dark:text-light-100"
                  >
                    Organization
                  </label>
                  <div className="mt-1">
                    <select
                      id="businessUnitKey"
                      name="businessUnitKey"
                      required
                      className="input input-primary"
                      onChange={handleChange}
                    >
                      <option value={null}></option>
                      {superUserBusinessUnits.map((bu: BusinessUnit) => (
                        <option key={bu.key} value={bu.key}>
                          {bu.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {subModal ? (
                <div>
                  <ArrowLeftIcon
                    className="w-4 cursor-pointer text-accent-400 hover:text-accent-500"
                    onClick={backToLogin}
                  />
                </div>
              ) : (
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="rememberMe"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-accent-400 focus:ring-accent-500"
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900 dark:text-light-100">
                        {formatMessage({ id: 'rememberMe', defaultMessage: 'Remember me' })}
                      </label>
                    </div>

                    <div className="text-sm">
                      <span
                        className="cursor-pointer font-medium text-accent-400 hover:text-accent-500"
                        onClick={toResendPassword}
                      >
                        {formatAccountMessage({ id: 'password.forgot', defaultMessage: 'Forgot your password?' })}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center justify-end">
                    <div className="text-sm">
                      <span
                        className="cursor-pointer font-medium text-accent-400 hover:text-accent-500"
                        onClick={toResendVerification}
                      >
                        {formatAccountMessage({
                          id: 'verification.resend',
                          defaultMessage: 'Bestätigungsmail erneut senden',
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-accent-400 py-2 px-4 text-sm font-medium text-white shadow-sm transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
                  disabled={loading}
                >
                  <div className="flex flex-row items-center">
                    <span>
                      {resendVerification
                        ? formatMessage({ id: 'submit', defaultMessage: 'Submit' })
                        : formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign in' })}
                    </span>
                    {loading && <LoadingIcon className="h-4 w-4 animate-spin" />}
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
