import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import ProductDetailsBreadcrumbs from '@CommerceToolsUI/products/product-details/product-details-breadcrumbs';
import Breadcrumb from 'components/commercetools-ui/breadcrumb';
import Filters from 'components/commercetools-ui/filters';
import CloseIcon from 'components/icons/icon-x';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { updateURLParams } from 'helpers/utils/updateURLParams';
import { useProducts } from '../../../../frontastic';
import List from './list';
import PaginatedItems from './pagination';

// import List from './List';
export interface Props {
  products: Product[];
  previousCursor: string;
  nextCursor: string;
  category: string;
  facets: Facet[];
  totalProducts: number;
  isPreview?: boolean;
  previewURL?: string;
}

export default function ProductList({
  products,
  totalProducts,
  previousCursor,
  nextCursor,
  category,
  facets,
  isPreview,
  previewURL,
}: Props) {
  const [isFiltering, setIsFiltering] = useState<boolean>(true);
  // const [previousPageURL, setPreviousPageURL] = useState<string>('/');
  // const [nextPageURL, setNextPageURL] = useState<string>('/');
  const [isTabletSize] = useMediaQuery(tablet);
  const { categories } = useProducts();
  const [pageCount, setPageCount] = useState(1);
  const router = useRouter();
  const itemsPerPage = 24;

  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const toggleFiltering = () => {
    if (!isTabletSize) {
      setIsFiltering(true);
      document.body.classList.add('filter_open');
    }
  };

  const closeFiltering = () => {
    setIsFiltering(false);
    document.body.classList.remove('filter_open');
  };

  useEffect(() => {
    // if (previousCursor) {
    //   setPreviousPageURL(updateURLParams([{ key: 'cursor', value: previousCursor }]));
    // }

    // if (nextCursor) {
    //   setNextPageURL(updateURLParams([{ key: 'cursor', value: nextCursor }]));
    // }
    setPageCount(Math.ceil(totalProducts / itemsPerPage));
  }, [previousCursor, nextCursor, totalProducts]);

  useEffect(() => {
    isTabletSize ? setIsFiltering(true) : setIsFiltering(false);
  }, [isTabletSize]);

  const onPageChange = (selectedPage) => {
    const newOffset = (selectedPage.selected * itemsPerPage) % totalProducts;
    const currentURL = updateURLParams([{ key: 'cursor', value: `offset:${newOffset}` }]);
    router.push(currentURL);
  };

  function getCategoriesTree(category: string) {
    const categoriesCurrent = categories.filter((o) => '/' + o.slug == category);
    if (categoriesCurrent.length) {
      const categoryPath = categoriesCurrent['0'].path;
      return categoryPath
        .split('/')
        .slice(1)
        .map((e) => {
          return categories.filter((o) => o.categoryId == e)['0'];
        });
    }
  }
  const categoriesTree = getCategoriesTree(category);
  return (
    <div className="mt-10 md:mt-4">
      {categoriesTree && <ProductDetailsBreadcrumbs categories={categoriesTree}></ProductDetailsBreadcrumbs>}
      {isPreview && (
        <span className="mt-2 inline-block rounded-sm bg-orange-300 p-2 text-sm font-light text-white">
          Displaying products in preview/staging mode.
        </span>
      )}
      {isFiltering ? (
        <div className="categories-page mt-10 block md:flex">
          {products.length > 0 ? (
            <div className="filters mt-6">
              <button onClick={toggleFiltering} className="filters-label mb-8 pb-4">
                <span>{formatProductMessage({ id: 'sortAndFilter', defaultMessage: 'Filter' })}</span>
              </button>
              <div className="filter-facets">
                <div className="close-btn md:hidden" onClick={closeFiltering}>
                  <CloseIcon />
                </div>
                <Filters facets={facets} products={products} closeFiltering={closeFiltering} />
              </div>
            </div>
          ) : (
            <div className="filters mt-6"></div>
          )}

          <div className="product-lists">
            {products.length > 0 ? (
              <>
                <div className="number-of-items mb-6">
                  {`${products?.length} ${formatProductMessage({
                    id: 'items',
                    defaultMessage: 'Items',
                  })} ${totalProducts}`}
                </div>
                <List products={products} filtering={isFiltering} isPreview={isPreview} previewURL={previewURL} />
                {totalProducts > itemsPerPage && (
                  <div className="categories-pagination">
                    <PaginatedItems itemsPerPage={itemsPerPage} pageCount={pageCount} onPageChange={onPageChange} />
                  </div>
                )}
              </>
            ) : (
              <p className="mt-10">
                {formatProductMessage({ id: 'noProductsFound', defaultMessage: 'No products found.' })}
              </p>
            )}
          </div>
        </div>
      ) : (
        <div>
          {products.length > 0 ? (
            <div>
              <div>
                <button onClick={toggleFiltering} className="w-full">
                  <div className="filters-label">
                    {formatProductMessage({ id: 'sortAndFilter', defaultMessage: 'Filter' })}
                  </div>
                </button>
                <div className="my-10 flex content-between">
                  <div className="lg:hidden">
                    {`${products?.length} ${formatProductMessage({
                      id: 'items',
                      defaultMessage: 'Items',
                    })} ${totalProducts}`}
                  </div>
                </div>
                <List products={products} isPreview={isPreview} previewURL={previewURL} />
                {totalProducts > itemsPerPage && (
                  <div className="categories-pagination">
                    <PaginatedItems itemsPerPage={itemsPerPage} pageCount={pageCount} onPageChange={onPageChange} />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <p className="my-10">
                {formatProductMessage({ id: 'noProductsFound', defaultMessage: 'No products found.' })}
              </p>
            </div>
          )}
        </div>
      )}

      {/* <nav
        className="mt-10 flex items-center justify-between border-t border-gray-200 py-3 px-4 sm:px-6"
        aria-label="Pagination"
      >
        <div className="flex flex-1 justify-between gap-x-1.5 sm:justify-end">
          <NextLink href={previousPageURL}>
            <a className={previousCursor ? activeButtonClassName : disabledButtonClassName}>
              {formatMessage({ id: 'prev', defaultMessage: 'Previous' })}
            </a>
          </NextLink>
          <NextLink href={nextPageURL}>
            <a className={nextCursor ? activeButtonClassName : disabledButtonClassName}>
              {formatMessage({ id: 'next', defaultMessage: 'Next' })}
            </a>
          </NextLink>
        </div>
      </nav> */}
    </div>
  );
}
