import React, { Ref, useEffect, useRef, useState } from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import { Variant } from '@Types/product/Variant';
import { LoadingIcon } from 'components/commercetools-ui/icons/loading';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import { useBusinessUnitStateContext } from 'frontastic/provider/BusinessUnitState';

type Props = {
  selectedSubscriptions?: Variant[];
  selectedConfigurableComponents?: Variant[];
  quantity?: number;
  variant: Variant;
  disabled?: boolean;
  onAddedToCart?: () => void;
};

const AddToCartButton: React.FC<Props> = ({
  selectedSubscriptions,
  selectedConfigurableComponents,
  quantity = 1,
  variant,
  disabled,
  onAddedToCart,
}) => {
  const { addItem, data: cart } = useCart();
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [inventoryNone] = useState(cart?.inventoryMode == 'None');
  const { businessUnit } = useBusinessUnitStateContext();
  const qtyMode = businessUnit.custom.fields.quantityMode ?? "sku";
  const [step] = useState(qtyMode === "sku" ? parseInt(variant?.attributes['bic_qty_increment'] || '1') : parseInt(variant?.attributes['number_of_sku_by_outer'] || '1'));
  const [min] = useState(qtyMode === "sku" ? parseInt(variant?.attributes['bic_qty_increment'] || step) : parseInt(variant?.attributes['number_of_sku_by_outer'] || step));
  const [max] = useState(qtyMode === "sku" ?  parseInt(variant?.attributes['bic_max_sale_qty'] || 999999999) : 999999999);
  const [count, setCount] = useState(Number(min));
  const qtyInputRef = useRef<HTMLInputElement>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);

  const handleAddToCart = async (variant: Variant) => {
    if (true === qtyInputRef.current.reportValidity()) {
      setIsLoading(true);
      await addItem(variant, count, selectedSubscriptions, selectedConfigurableComponents);
      setIsLoading(false);
      setAdded(true);
      if (onAddedToCart) {
        onAddedToCart();
      }
    }
  };

  const handleChange = (e) => {
    setCount(e.target.value);
  };

  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false);
      }, 1000);
    }
  }, [added]);

  if (!cart) {
    return (
      <button type="button" className="btn-primary mt-8" disabled>
        {formatProductMessage({ id: 'no.cart', defaultMessage: 'No cart available' })}
      </button>
    );
  }

  return (
    <>
      <div className="product-qty-wrapper mx-auto">
        <input
          value={count}
          type="number"
          ref={qtyInputRef}
          disabled={isLoading}
          onChange={handleChange}
          className="product-qty-input p-0"
          step={step}
          min={min}
          max={max}
        />
      </div>
      {!cart.isPreBuyCart && (
        <button
          type="button"
          onClick={() => handleAddToCart(variant)}
          className="btn-primary mt-8"
          disabled={!variant.isOnStock && !inventoryNone || isLoading || !inventoryNone && disabled}
        >
          {!isLoading && !added && (
            <>
              {variant.isOnStock || inventoryNone
                ? formatProductMessage({ id: 'cart.add', defaultMessage: 'Add to Cart' })
                : formatProductMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' })}
            </>
          )}

          {isLoading && <LoadingIcon className="h-6 w-6 animate-spin" />}
          {!isLoading && added && <CheckIcon className="h-6 w-6" />}
        </button>
      )}
      {cart.isPreBuyCart && (
        <button
          type="button"
          onClick={() => handleAddToCart(variant)}
          className="btn-primary mt-8"
          disabled={isLoading || disabled}
        >
          {!isLoading && !added && formatProductMessage({ id: 'cart.add', defaultMessage: 'Add to Cart' })}

          {isLoading && <LoadingIcon className="h-6 w-6 animate-spin" />}
          {!isLoading && added && <CheckIcon className="h-6 w-6" />}
        </button>
      )}
    </>
  );
};

export default AddToCartButton;
