/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { Address } from '@Types/account/Address';
import { getAddress, getAddressByIdAndType, getAddresses, getFirstAddress } from 'bic/helpers/hooks/useBicBusinessUnit';
import { useFormat } from 'helpers/hooks/useFormat';
import { mapAddressToString } from 'helpers/utils/addressUtil';
import { useAccount } from 'frontastic';
import { useBusinessUnitStateContext } from 'frontastic/provider/BusinessUnitState';
import { FormData } from '../';

interface Props {
  updateSelection: (address: object, forceCartUpdate: boolean) => void;
  addressType?: string;
  data: FormData;
}

const AddressSelection: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  updateSelection,
  className,
  data,
  addressType = 'shipping'
}) => {
  const { businessUnit } = useBusinessUnitStateContext();
  const address = getAddress(addressType, businessUnit);
  const { formatMessage } = useFormat({ name: 'business-unit' });
  const { account } = useAccount();
  const [selectedAddress, setSelectedAddress] = useState(null);


  const updateAddress = (address: Address, forceCartUpdate: boolean) => {
    updateSelection(address, forceCartUpdate);
    addressType === 'shipping' ? setSelectedShippingAddress(address) : setSelectedBillingAddress(address);
  };

  const setSelectedShippingAddress = (address: Address) => {
    localStorage.setItem('selectedShippingAddressId', address.id);
  }

  const setSelectedBillingAddress = (address: Address) => {
    localStorage.setItem('selectedBillingAddressId', address.id);
  }

  const getSelectedShippingAddress = () => {
    return localStorage.getItem('selectedShippingAddressId');
  }

  const getSelectedBillingAddress = () => {
    return localStorage.getItem('selectedBillingAddressId');
  }

  const addressSelectionHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const address = businessUnit.addresses.find((address) => address.id === event.target.value);
    updateAddress(address, true);
  };
  const addressList = getAddresses(addressType, businessUnit);

  useEffect(() => {
    const selectedAddressId = 'shipping' === addressType ? getSelectedShippingAddress() : getSelectedBillingAddress();
    const selectedAddress = (selectedAddressId === null) ? getFirstAddress(addressType, businessUnit) : getAddressByIdAndType(selectedAddressId, addressType, businessUnit);
    const inList = addressList.find((address) => selectedAddress?.id === address.id);
    if(selectedAddress && inList) {
      updateAddress(selectedAddress, false);
    }
  });

  return (
    <div className={className}>
      <label className="text-sm leading-tight text-neutral-700" htmlFor="billing-country">
        <span>{formatMessage({ id: 'select-address', defaultMessage: 'Select a address' })}</span>
      </label>
      <select onChange={addressSelectionHandler} className="input input-primary" defaultValue={addressType === 'shipping' ? getSelectedShippingAddress() : getSelectedBillingAddress()}>
        {addressList.map((address) => (
          <option
            key={address.id}
            value={address.id}
          >
            {mapAddressToString(address)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AddressSelection;
