import React from 'react';
import NextLink from 'next/link';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { useFormat } from 'helpers/hooks/useFormat';
import { StringHelpers } from 'helpers/stringHelpers';
import { getSelectedBundleLabel, BUNDLE_ATTRIBUTE_NAME } from 'helpers/utils/bundleItemsHelpers';
import Image from 'frontastic/lib/image';

export interface Props {
  readonly cart: Cart;
}

const CartItems: React.FC<Props> = ({ cart }) => {
  const { formatMessage } = useFormat({ name: 'checkout' });

  return (
    <ul role="list" className="flex-auto divide-y divide-gray-200 overflow-y-auto px-4 md:px-0">
      {cart?.lineItems?.map((lineItem, i) => (
        <li key={i} className="flex items-center space-x-6 py-6">
          <Image
            src={lineItem.variant.images[0]}
            alt={lineItem.name}
            className="h-20 w-20 flex-none rounded-md bg-gray-200 object-contain object-center"
          />
          <div className="md:flex w-full justify-between">
            <div className="space-y-1 text-sm font-medium">
              <h3 className="text-base text-gray-900 dark:text-light-100">
                <NextLink href={lineItem._url}>
                  <a className="block hover:text-accent-400">
                    <span className="pr-2">{`${lineItem.count}x`}</span> {lineItem.name}
                  </a>
                </NextLink>
              </h3>
              {!!lineItem.variant?.attributes?.[BUNDLE_ATTRIBUTE_NAME]?.length && (
                <div className="flex flex-col">
                  {lineItem.variant?.attributes?.[BUNDLE_ATTRIBUTE_NAME].map((subscription: LineItem) => (
                    <div className="td-other-details td-details__sku" key={subscription.lineItemId}>
                      <label className="">{`${subscription.name}: `}</label>
                      <span className="text-xs">{getSelectedBundleLabel(subscription.variant, subscription.name)}</span>
                    </div>
                  ))}
                </div>
              )}
              {lineItem.variant.attributes?.color && (
                <p className="text-gray-500 dark:text-light-100">
                  {StringHelpers.capitaliseFirstLetter(lineItem.variant.attributes?.color?.label || '')}
                </p>
              )}
              {lineItem.variant.attributes?.size && (
                <p className="capitalize text-gray-500 dark:text-light-100">{lineItem.variant.attributes.size}</p>
              )}
            </div>
            <div className="shipping-details mt-2 md:mt-0 md:ml-6">
              {lineItem?.shippingDetails !== undefined
                ? `${formatMessage({
                    id: 'shippingMethodsSplitMessage',
                    defaultMessage: 'Shipped to multiple addresses, selected during previous step (cart)',
                  })}`
                : `${formatMessage({
                    id: 'shippingMethodsNoSplitMessage',
                    defaultMessage: 'Shipped to selected address',
                  })}`}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default CartItems;
