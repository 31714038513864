import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
  onPaymentUpdate: (payload: { poNumber: string, expectedDeliveryDate: string }) => void;
  expectedDeliveryDate: string;
  inputPoNumberRef: React.Ref<HTMLInputElement>
}
const PoNumber: React.FC<Props> = ({ onPaymentUpdate, expectedDeliveryDate, inputPoNumberRef }) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const [data, setData] = useState({
    poNumber: '',
    invoiceMemo: '',
    comment: '',
    expectedDeliveryDate: expectedDeliveryDate
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setData({
      ...data,
      expectedDeliveryDate: expectedDeliveryDate,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    onPaymentUpdate({ poNumber: data.poNumber, expectedDeliveryDate: expectedDeliveryDate});
  }, [data, expectedDeliveryDate]);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="mt-4">
        <p className="text-sm text-gray-500">
          {formatMessage({
            id: 'po-number-description',
            defaultMessage: 'Please enter a single authorized purchase order number generated by your organization.',
          })}
        </p>
        <p className="text-sm text-gray-500">
          {formatMessage({
            id: 'po-number-description-2',
            defaultMessage: 'The PO number and payment instructions will appear on the invoice.',
          })}
        </p>
        <label className="text-sm leading-tight text-neutral-700" htmlFor="billing-country">
          <span>{formatMessage({ id: 'po-number', defaultMessage: 'PO Number' })}</span> *
          <input
            className="input input-primary"
            id="po-number"
            name="poNumber"
            type="text"
            ref={inputPoNumberRef}
            onChange={handleChange}
            value={data.poNumber}
            required
          />
        </label>
      </div>
      {/* <div>
        <label className="text-sm leading-tight text-neutral-700" htmlFor="billing-country">
          <span>{formatMessage({ id: 'po-number-invoice', defaultMessage: 'Invoice Memo (optional)' })}</span>
          <input
            className="input input-primary"
            id="invoice-memo"
            name="invoiceMemo"
            type="text"
            onChange={handleChange}
            value={data.invoiceMemo}
          />
        </label>
      </div> */}
    </form>
  );
};

export default PoNumber;
