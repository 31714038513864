import React from 'react';
import { LineItem } from '@Types/cart/LineItem';
import { useFormat } from 'helpers/hooks/useFormat';
import { BicOrderLineItem } from './order-line-item';

interface Props {
  lineItems: LineItem[];
  bicLineItems?: any;
  bicOrder?: any;
}

export const BicOrderItems: React.FC<Props> = ({ lineItems, bicLineItems, bicOrder }) => {
  if (null === bicLineItems) {
    return null;
  }

  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  return (
    <table className="mt-4 w-full text-gray-500 sm:mt-6">
      <caption className="sr-only">
        {formatProductMessage({
          id: 'products',
          defaultMessage: 'Products',
        })}
      </caption>
      <thead className="sr-only text-left text-sm text-gray-800 sm:not-sr-only">
        <tr>
          <th scope="col" className="py-1 pr-8 font-normal dark:text-light-100 sm:w-2/5 lg:w-1/3">
            {formatProductMessage({
              id: 'product',
              defaultMessage: 'Product',
            })}
          </th>
          <th scope="col" className="py-1 pr-8 font-normal dark:text-light-100 sm:w-2/5 lg:w-1/3">
            {formatProductMessage({
              id: 'sku',
              defaultMessage: 'sku',
            })}
          </th>
          <th scope="col" className="hidden w-1/5 py-1 pr-8 font-normal dark:text-light-100 sm:table-cell">
            {formatProductMessage({
              id: 'price',
              defaultMessage: 'Price',
            })}
          </th>
          <th scope="col" className="hidden py-1 pr-8 font-normal dark:text-light-100 sm:table-cell">
            {formatProductMessage({
              id: 'quantity',
              defaultMessage: 'Quantity',
            })}
          </th>
          <th scope="col" className="hidden w-1/5 py-1 pr-8 font-normal dark:text-light-100 sm:table-cell">
            {formatProductMessage({
              id: 'subtotal',
              defaultMessage: 'Subtotal',
            })}
          </th>
          <th scope="col" className="hidden w-1/5 py-1 pr-8 font-normal dark:text-light-100 sm:table-cell">
            {formatProductMessage({
              id: 'orderItemStatus',
              defaultMessage: 'Status',
            })}
          </th>
          <th scope="col" className="hidden w-1/5 py-1 pr-8 font-normal dark:text-light-100 sm:table-cell">
            {formatProductMessage({
              id: 'orderItemInvoice',
              defaultMessage: 'Invoice',
            })}
          </th>
          <th scope="col" className="hidden w-1/5 py-1 pr-8 font-normal dark:text-light-100 sm:table-cell">
            {formatProductMessage({
              id: 'orderItemDelivertNote',
              defaultMessage: 'Delivery note',
            })}
          </th>
          <th scope="col" className="hidden w-1/5 py-1 pr-8 font-normal dark:text-light-100 sm:table-cell">
            {formatProductMessage({
              id: 'orderItemPackingList',
              defaultMessage: 'Packing list',
            })}
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 border-b border-gray-200 text-sm sm:border-t">
        {bicLineItems.map((lineItem) => (
          <BicOrderLineItem key={lineItem.erp_line_id} lineItem={lineItem} bicOrder={bicOrder} />
        ))}
      </tbody>
    </table>
  );
};
